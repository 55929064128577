"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.tsx
var src_exports = {};
__export(src_exports, {
  ActionBarComponent: () => ActionBarComponent,
  components: () => components,
  customRoutes: () => customRoutes
});
module.exports = __toCommonJS(src_exports);

// src/components/ListViewCustomComponents/ApplicationResponseAction.tsx
var ApplicationResponseAction_exports = {};
__export(ApplicationResponseAction_exports, {
  config: () => config,
  default: () => ApplicationResponseAction_default
});
var import_react2 = require("react");
var import_stack9_sdk2 = require("@april9/stack9-sdk");
var ui4 = __toESM(require("@april9/stack9-ui"));

// src/components/ResponseDrawer/ResponseDrawer.tsx
var ui3 = __toESM(require("@april9/stack9-ui"));

// src/components/ResponseDrawer/ResponseForm.tsx
var import_react = require("react");
var import_stack9_react = require("@april9/stack9-react");
var import_stack9_sdk = require("@april9/stack9-sdk");
var ui2 = __toESM(require("@april9/stack9-ui"));

// src/constants/entities.ts
var entityNames = {
  ACCOUNT: "account",
  APPLICATION: "application",
  APPLICATION_RESPONSE: "application_response",
  APPLICATION_DOCUMENT: "application_document",
  BASE_REQUIREMENT: "base_requirement",
  BASE_OPTION_SET: "base_option_set",
  COUNTRY: "country",
  COUNTRY_SETUP: "country_setup",
  SURVEY_QUESTION: "survey_question",
  COUNTRY_TEMPLATE: "country_template",
  DEPENDENCY: "dependency",
  INDUSTRY: "industry",
  LANGUAGE: "language",
  ORGANISATION_SIZE: "organisation_size",
  ORGANISATION: "organisation",
  OCCUPATION: "occupation",
  OPTION_SET: "option_set",
  PARTNER: "partner",
  POSITION: "position",
  PROMO_CODE: "promo_code",
  PRICE_RULE: "price_rule",
  REQUIREMENT: "requirement",
  SECONDARY_INDUSTRY: "secondary_industry",
  USER: "user"
};

// src/components/ListTable/ListTable.tsx
var ui = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime = require("react/jsx-runtime");
var { Grid } = ui;
var S9PageSection2 = ui.S9PageSection.default;
var ListTable = ({
  queryVars,
  queryName,
  title,
  columns,
  searchFields = [],
  showFilter = false,
  children
}) => /* @__PURE__ */ (0, import_jsx_runtime.jsxs)(S9PageSection2, { title, size: "sm", children: [
  children,
  /* @__PURE__ */ (0, import_jsx_runtime.jsx)(
    Grid,
    {
      name: queryName,
      columnsConfiguration: columns,
      querySearchFields: searchFields,
      showFilter,
      searchQueryKey: queryName,
      queryVars
    }
  )
] });

// src/components/ResponseDrawer/ResponseForm.tsx
var import_jsx_runtime2 = require("react/jsx-runtime");
var { useScreen } = ui2;
var { useForm } = ui2.S9Form;
var { useRefreshList } = ui2;
var S9Drawer2 = ui2.S9Drawer.default;
var S9Form2 = ui2.S9Form.default;
var S9Fieldset2 = ui2.S9Fieldset.default;
var S9EntityActions2 = ui2.S9EntityActions.default;
var ResponseForm = ({
  onClose,
  initialValues,
  id
}) => {
  const [form] = useForm();
  const { screen } = useScreen();
  const screenKey = (0, import_stack9_sdk.ep)(screen && screen.head.key);
  const formFields = [
    { field: "requirement_code", colSize: 12 },
    { field: "status", colSize: 12 },
    { field: "response_option" },
    { field: "response_text" },
    { field: "assessor_comment" }
  ];
  const { queryService } = (0, import_stack9_react.useStack9)();
  const { schema, isValidating } = (0, import_stack9_react.useEntitySchema)(
    entityNames.APPLICATION_RESPONSE
  );
  const refresh = useRefreshList();
  const handleSave = (0, import_react.useCallback)(
    (values) => __async(void 0, null, function* () {
      var _a, _b, _c, _d;
      yield queryService.runNamedQuery(
        screenKey,
        "updateApplicationResponseDrawer",
        {
          vars: {
            requirement_code: values.requirement_code,
            status: (_a = values.status) != null ? _a : null,
            response_option: (_b = values.response_option) != null ? _b : null,
            response_text: (_c = values.response_text) != null ? _c : null,
            assessor_comment: (_d = values.assessor_comment) != null ? _d : null,
            id
          }
        }
      );
      onClose == null ? void 0 : onClose();
      refresh();
    }),
    [id, onClose, queryService, refresh, screenKey]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(
    S9Form2,
    {
      isValidating,
      onFinish: handleSave,
      loading: isValidating,
      form,
      initialValues,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
          S9Drawer2.Header,
          {
            title: "Application response",
            showCancel: true,
            extra: /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
              S9EntityActions2,
              {
                entityKey: entityNames.APPLICATION_RESPONSE,
                entityId: id,
                form
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(S9Drawer2.Content, { children: schema && /* @__PURE__ */ (0, import_jsx_runtime2.jsxs)(import_jsx_runtime2.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            S9Fieldset2,
            {
              context: { id: void 0 },
              schema,
              formFields
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime2.jsx)(
            ListTable,
            {
              queryVars: { applicationResponseId: id },
              queryName: "getapplicationdocumentbyapplicationresponse",
              title: "Application documents",
              columns: [
                {
                  label: "Code",
                  field: "code",
                  value: "{{code}}",
                  renderAs: import_stack9_sdk.ColumnConfigurationRenderAs.Text
                },
                {
                  label: "Files",
                  field: "file",
                  value: "{{file}}",
                  renderAs: import_stack9_sdk.ColumnConfigurationRenderAs.CustomComponent,
                  options: {
                    customComponentName: "FileView"
                  }
                }
              ]
            }
          )
        ] }) })
      ]
    }
  );
};

// src/components/ResponseDrawer/ResponseDrawer.tsx
var import_jsx_runtime3 = require("react/jsx-runtime");
var { ScreenProvider } = ui3;
var S9Drawer4 = ui3.S9Drawer.default;
var ResponseDrawer = ({
  id,
  open,
  onClose,
  initialValues
}) => /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(ScreenProvider, { relativePath: "/app/application-detail/:id" /* ApplicationDetail */, children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(S9Drawer4, { open, width: 650, onClose, closable: true, children: /* @__PURE__ */ (0, import_jsx_runtime3.jsx)(ResponseForm, { initialValues, id, onClose }) }) });

// src/components/ListViewCustomComponents/ApplicationResponseAction.tsx
var import_jsx_runtime4 = require("react/jsx-runtime");
var S9Button2 = ui4.S9Button.default;
var config = {
  name: "ApplicationResponseAction",
  icon: "",
  category: import_stack9_sdk2.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk2.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var ApplicationResponseAction = ({ row }) => {
  const [selectedResponse, openResponseDrawer] = (0, import_react2.useState)({ open: false });
  const refresh = ui4.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime4.jsxs)(import_jsx_runtime4.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
      S9Button2,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => openResponseDrawer({ id: row.id, open: true }),
        label: `${row.id}`
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime4.jsx)(
      ResponseDrawer,
      {
        initialValues: row,
        id: row.id,
        open: selectedResponse.open,
        onClose: () => {
          openResponseDrawer({ id: void 0, open: false });
          refresh();
        }
      }
    )
  ] });
};
var ApplicationResponseAction_default = ApplicationResponseAction;

// src/components/ListViewCustomComponents/BaseOptionSetAction.tsx
var BaseOptionSetAction_exports = {};
__export(BaseOptionSetAction_exports, {
  config: () => config2,
  default: () => BaseOptionSetAction_default
});
var import_react3 = require("react");
var import_stack9_sdk3 = require("@april9/stack9-sdk");
var ui6 = __toESM(require("@april9/stack9-ui"));

// src/components/EntityDrawer/EntityDrawer.tsx
var ui5 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime5 = require("react/jsx-runtime");
var { ScreenProvider: ScreenProvider2 } = ui5;
var S9EntityCreateDrawer2 = ui5.S9EntityCreateDrawer.default;
var S9EntityEditDrawer2 = ui5.S9EntityEditDrawer.default;
var EntityDrawer = ({
  open,
  onClose,
  id,
  screenQueryName,
  entityName,
  title,
  editRoute,
  relativePath,
  initialValues = {},
  formFields = []
}) => /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(ScreenProvider2, { relativePath, children: id === void 0 ? /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
  S9EntityCreateDrawer2,
  {
    title: `Create ${title}`,
    open,
    onClose,
    onSaved: onClose,
    initialValues,
    entityKey: entityName,
    formFields,
    editRoute,
    closable: true
  }
) : /* @__PURE__ */ (0, import_jsx_runtime5.jsx)(
  S9EntityEditDrawer2,
  {
    title: `Edit ${title}`,
    screenQueryName,
    open,
    entityKey: entityName,
    entityId: id,
    formFields,
    initialValues,
    closable: true,
    onClose,
    onSaved: onClose,
    onDeleted: onClose
  }
) });

// src/components/BaseOptionSetDrawer/BaseOptionSetDrawer.tsx
var import_jsx_runtime6 = require("react/jsx-runtime");
var BaseOptionSetDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "sequence", colSize: 12 },
    { field: "is_correct", colSize: 12 },
    { field: "option" },
    { field: "negative_response" },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime6.jsx)(
    EntityDrawer,
    {
      title: "base option set",
      formFields,
      initialValues,
      id,
      open,
      onClose,
      screenQueryName: "getBaseOptionSetDetails" /* GetBaseOptionSetDetails */,
      entityName: entityNames.BASE_OPTION_SET,
      editRoute: "/ccfg/base-requirement-detail/:id" /* BaseRequirementDetail */,
      relativePath: "/ccfg/base-requirement-detail/:id" /* BaseRequirementDetail */
    }
  );
};

// src/components/ListViewCustomComponents/BaseOptionSetAction.tsx
var import_jsx_runtime7 = require("react/jsx-runtime");
var S9Button4 = ui6.S9Button.default;
var { useRefreshList: useRefreshList3 } = ui6;
var config2 = {
  name: "BaseOptionSetAction",
  icon: "",
  category: import_stack9_sdk3.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk3.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var BaseOptionSetAction = ({ row }) => {
  const refresh = useRefreshList3();
  const [drawerState, setDrawerState] = (0, import_react3.useState)({ open: false });
  return /* @__PURE__ */ (0, import_jsx_runtime7.jsxs)(import_jsx_runtime7.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
      S9Button4,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setDrawerState({ id: row.id, open: true }),
        label: `${row.option}`
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime7.jsx)(
      BaseOptionSetDrawer,
      {
        initialValues: row,
        id: row.id,
        open: drawerState.open,
        onClose: () => {
          setDrawerState({ id: void 0, open: false });
          refresh();
        }
      }
    )
  ] });
};
var BaseOptionSetAction_default = BaseOptionSetAction;

// src/components/ListViewCustomComponents/DependencyAction.tsx
var DependencyAction_exports = {};
__export(DependencyAction_exports, {
  config: () => config3,
  default: () => DependencyAction_default
});
var import_react7 = require("react");
var import_stack9_sdk5 = require("@april9/stack9-sdk");
var ui9 = __toESM(require("@april9/stack9-ui"));

// src/components/DependencyDrawer/DependencyDrawer.tsx
var ui8 = __toESM(require("@april9/stack9-ui"));

// src/components/DependencyDrawer/DependencyForm.tsx
var import_react6 = require("react");
var import_stack9_react2 = require("@april9/stack9-react");
var import_stack9_sdk4 = require("@april9/stack9-sdk");
var ui7 = __toESM(require("@april9/stack9-ui"));

// src/components/DependencyDrawer/OptionSetDropDown.tsx
var import_react4 = require("react");
var import_stack9_ui = require("@april9/stack9-ui");
var import_jsx_runtime8 = require("react/jsx-runtime");
var OptionSetDropdown = ({
  requirement,
  setOptionSet,
  optionSet
}) => {
  const { data } = (0, import_stack9_ui.useScreenQuery)("getOptionsetByRequirement", {
    vars: {
      requirementId: requirement,
      page: 0,
      limit: 10
    }
  });
  const optionsOptionSet = (0, import_react4.useMemo)(
    () => {
      var _a;
      return (_a = data == null ? void 0 : data.results) == null ? void 0 : _a.map((opt) => ({
        label: opt.option,
        value: opt.id
      }));
    },
    [data]
  );
  const onChangeOptionSet = (0, import_react4.useCallback)(() => {
    const selectedElement = document.getElementById("option_set_id");
    setOptionSet(selectedElement == null ? void 0 : selectedElement.value);
  }, [setOptionSet]);
  return /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)("div", { className: "block text-sm  leading-6 text-gray-900", children: [
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("label", { htmlFor: "option_set_id", children: "Option set" }),
    /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("div", { className: "relative", children: /* @__PURE__ */ (0, import_jsx_runtime8.jsxs)(
      "select",
      {
        required: true,
        onChange: onChangeOptionSet,
        className: "mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
        id: "option_set_id",
        style: { minWidth: "100%" },
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime8.jsx)("option", { value: "", disabled: true, selected: true, children: "Select option" }),
          optionsOptionSet && optionsOptionSet.map((opt) => /* @__PURE__ */ (0, import_jsx_runtime8.jsx)(
            "option",
            {
              selected: opt.value === optionSet,
              value: opt.value,
              children: opt.label
            },
            opt.value
          ))
        ]
      }
    ) })
  ] });
};
var OptionSetDropDown_default = OptionSetDropdown;

// src/components/DependencyDrawer/RequirementDropdown.tsx
var import_react5 = require("react");
var import_stack9_ui2 = require("@april9/stack9-ui");
var import_jsx_runtime9 = require("react/jsx-runtime");
var RequirementDropdown = ({
  countryTemplateId,
  setRequirement,
  requirement
}) => {
  const { data } = (0, import_stack9_ui2.useScreenQuery)("getRequirementsByCountryTemplate", {
    vars: {
      countryTemplateId,
      page: 0,
      limit: 10
    }
  });
  const options = (0, import_react5.useMemo)(
    () => {
      var _a;
      return (_a = data == null ? void 0 : data.results) == null ? void 0 : _a.map((opt) => ({
        label: opt.code,
        value: opt.id
      }));
    },
    [data]
  );
  const onChange = (0, import_react5.useCallback)(() => {
    const selectedElement = document.getElementById("requirement_id");
    setRequirement(selectedElement == null ? void 0 : selectedElement.value);
  }, [setRequirement]);
  return /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)("div", { className: "block text-sm  leading-6 text-gray-900", children: [
    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("label", { htmlFor: "requirement_id", children: "Requirement section" }),
    /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("div", { className: "relative", children: /* @__PURE__ */ (0, import_jsx_runtime9.jsxs)(
      "select",
      {
        required: true,
        onChange,
        className: "mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6",
        id: "requirement_id",
        style: { minWidth: "100%" },
        children: [
          /* @__PURE__ */ (0, import_jsx_runtime9.jsx)("option", { value: "", disabled: true, selected: true, children: "Select requirement" }),
          options && options.map((opt) => /* @__PURE__ */ (0, import_jsx_runtime9.jsx)(
            "option",
            {
              selected: opt.value === requirement,
              value: opt.value,
              children: opt.label
            },
            opt.value
          ))
        ]
      }
    ) })
  ] });
};
var RequirementDropdown_default = RequirementDropdown;

// src/components/DependencyDrawer/DependencyForm.tsx
var import_jsx_runtime10 = require("react/jsx-runtime");
var { useScreen: useScreen2 } = ui7;
var { useForm: useForm2 } = ui7.S9Form;
var S9Form4 = ui7.S9Form.default;
var S9EntityActions4 = ui7.S9EntityActions.default;
var S9Col2 = ui7.S9Col.default;
var S9Row2 = ui7.S9Row.default;
var S9Drawer6 = ui7.S9Drawer.default;
var DependencyForm = ({
  countryTemplateId,
  onClose,
  id,
  initialValues
}) => {
  var _a, _b;
  const { queryService } = (0, import_stack9_react2.useStack9)();
  const { screen } = useScreen2();
  const screenKey = (0, import_stack9_sdk4.ep)(screen && screen.head.key);
  const { schema, isValidating } = (0, import_stack9_react2.useEntitySchema)(entityNames.DEPENDENCY);
  const [form] = useForm2();
  const [requirement, setRequirement] = (0, import_react6.useState)(
    (_a = initialValues == null ? void 0 : initialValues.requirement_id) != null ? _a : void 0
  );
  const [optionSet, setOptionSet] = (0, import_react6.useState)(
    (_b = initialValues == null ? void 0 : initialValues.option_set_id) != null ? _b : void 0
  );
  const handleSave = (0, import_react6.useCallback)(() => __async(void 0, null, function* () {
    const query = id ? "updateDependency" : "createDependency";
    yield queryService.runNamedQuery(screenKey, query, {
      vars: {
        requirement_id: requirement,
        option_set_id: optionSet,
        id
      }
    });
    setRequirement(void 0);
    setOptionSet(void 0);
    onClose == null ? void 0 : onClose();
  }), [id, onClose, optionSet, queryService, requirement, screenKey]);
  return /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(
    S9Form4,
    {
      isValidating,
      onFinish: handleSave,
      loading: isValidating,
      form,
      initialValues,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
          S9Drawer6.Header,
          {
            title: id ? "Edit dependency" : "Create dependency",
            showCancel: true,
            extra: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
              S9EntityActions4,
              {
                entityKey: entityNames.DEPENDENCY,
                onDeleted: () => {
                  setRequirement(void 0);
                  setOptionSet(void 0);
                  onClose == null ? void 0 : onClose();
                },
                entityId: id,
                form
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(S9Drawer6.Content, { children: schema && /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(import_jsx_runtime10.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime10.jsxs)(S9Row2, { gutter: 24, children: [
          /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(S9Col2, { md: 24, style: { marginBottom: "20px" }, children: /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
            RequirementDropdown_default,
            {
              setRequirement,
              countryTemplateId,
              requirement
            }
          ) }),
          /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(S9Col2, { md: 24, children: requirement && /* @__PURE__ */ (0, import_jsx_runtime10.jsx)(
            OptionSetDropDown_default,
            {
              setOptionSet,
              requirement,
              optionSet
            }
          ) })
        ] }) }) })
      ]
    }
  );
};

// src/components/DependencyDrawer/DependencyDrawer.tsx
var import_jsx_runtime11 = require("react/jsx-runtime");
var { ScreenProvider: ScreenProvider3, useScreenQuery: useScreenQuery3 } = ui8;
var S9Drawer8 = ui8.S9Drawer.default;
var DependencyDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const { data } = useScreenQuery3("getRequirementDetails", {
    vars: {
      id: initialValues == null ? void 0 : initialValues.requirement_id
    }
  });
  const requirement = data[0];
  return /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(ScreenProvider3, { relativePath: "/ccfg/requirement-detail/:id" /* RequirementDetail */, children: /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(S9Drawer8, { open, width: 650, onClose, closable: true, children: requirement && /* @__PURE__ */ (0, import_jsx_runtime11.jsx)(
    DependencyForm,
    {
      onClose,
      countryTemplateId: requirement.country_template_id,
      id,
      initialValues
    }
  ) }) });
};

// src/components/ListViewCustomComponents/DependencyAction.tsx
var import_jsx_runtime12 = require("react/jsx-runtime");
var S9Button6 = ui9.S9Button.default;
var { useRefreshList: useRefreshList4 } = ui9;
var config3 = {
  name: "DependencyAction",
  icon: "",
  category: import_stack9_sdk5.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk5.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var DependencyAction = ({ row }) => {
  const refresh = useRefreshList4();
  const [drawerState, setDrawerState] = (0, import_react7.useState)({ open: false });
  return /* @__PURE__ */ (0, import_jsx_runtime12.jsxs)(import_jsx_runtime12.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
      S9Button6,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setDrawerState({ id: row.id, open: true }),
        label: `${row.requirement.summary}`
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime12.jsx)(
      DependencyDrawer,
      {
        initialValues: row,
        id: row.id,
        open: drawerState.open,
        onClose: () => {
          setDrawerState({ id: void 0, open: false });
          refresh();
        }
      }
    )
  ] });
};
var DependencyAction_default = DependencyAction;

// src/components/ListViewCustomComponents/FileView.tsx
var FileView_exports = {};
__export(FileView_exports, {
  config: () => config4,
  default: () => FileView_default
});
var import_react8 = require("react");
var import_stack9_sdk6 = require("@april9/stack9-sdk");
var ui10 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime13 = require("react/jsx-runtime");
var S9Button8 = ui10.S9Button.default;
var config4 = {
  name: "FileView",
  icon: "",
  category: import_stack9_sdk6.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk6.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var FileView = ({ row }) => {
  const { file } = row;
  const fileItems = (0, import_react8.useMemo)(() => {
    const items = [];
    if (!file) {
      return items;
    }
    if (Array.isArray(file)) {
      items.push(...file);
    } else if (typeof file === "object" && "fileKey" in file) {
      items.push(file);
    }
    return items;
  }, [file]);
  if (!fileItems.length) {
    return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(import_jsx_runtime13.Fragment, { children: "-" });
  }
  return /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("ul", { className: "space-y-1.5", children: fileItems.map((item, idx) => /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)(
    "li",
    {
      className: "flex items-start justify-between flex-wrap gap-2",
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime13.jsxs)("span", { children: [
          idx + 1,
          ". "
        ] }),
        /* @__PURE__ */ (0, import_jsx_runtime13.jsx)("span", { className: "grow", children: item.name }),
        /* @__PURE__ */ (0, import_jsx_runtime13.jsx)(
          S9Button8,
          {
            icon: "ExportOutlined",
            type: "text",
            htmlType: "button",
            href: item && ui10.Utils.signPrivateBucketURLRoute(
              "application_document",
              item.fileKey,
              item.isPublic
            ),
            label: "View",
            target: "_blank"
          }
        )
      ]
    },
    item.name + item.fileKey
  )) });
};
var FileView_default = FileView;

// src/components/ListViewCustomComponents/IndustryDrawerAction.tsx
var IndustryDrawerAction_exports = {};
__export(IndustryDrawerAction_exports, {
  config: () => config5,
  default: () => IndustryDrawerAction_default
});
var import_react10 = require("react");
var import_stack9_sdk8 = require("@april9/stack9-sdk");
var ui13 = __toESM(require("@april9/stack9-ui"));

// src/components/IndustryDrawer/IndustryDrawer.tsx
var ui12 = __toESM(require("@april9/stack9-ui"));

// src/components/IndustryDrawer/IndustryForm.tsx
var import_react9 = require("react");
var import_stack9_react3 = require("@april9/stack9-react");
var import_stack9_sdk7 = require("@april9/stack9-sdk");
var ui11 = __toESM(require("@april9/stack9-ui"));

// src/components/SecondaryIndustryDrawer/SecondaryIndustryDrawer.tsx
var import_jsx_runtime14 = require("react/jsx-runtime");
var SecondaryIndustryDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "code" },
    { field: "name" },
    { field: "is_active" },
    { field: "lang_set" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime14.jsx)(
    EntityDrawer,
    {
      title: "secondary industry",
      formFields,
      initialValues,
      id,
      open,
      onClose,
      screenQueryName: "getSecondaryIndustryDetails" /* GetSecondaryIndustryDetails */,
      entityName: entityNames.SECONDARY_INDUSTRY,
      editRoute: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */,
      relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */
    }
  );
};

// src/components/IndustryDrawer/IndustryForm.tsx
var import_jsx_runtime15 = require("react/jsx-runtime");
var { useScreen: useScreen3 } = ui11;
var { useForm: useForm3 } = ui11.S9Form;
var S9Drawer10 = ui11.S9Drawer.default;
var S9Form6 = ui11.S9Form.default;
var S9Fieldset4 = ui11.S9Fieldset.default;
var S9EntityActions6 = ui11.S9EntityActions.default;
var Button = ui11.S9Button.default;
var { Space, useRefreshList: useRefreshList5 } = ui11;
var IndustryForm = ({
  id,
  onClose,
  initialValues
}) => {
  const [form] = useForm3();
  const { screen } = useScreen3();
  const screenKey = (0, import_stack9_sdk7.ep)(screen && screen.head.key);
  const columns = [
    {
      label: "Code",
      field: "code",
      renderAs: import_stack9_sdk7.ColumnConfigurationRenderAs.CustomComponent,
      value: "{{code}}",
      options: {
        customComponentName: "SecondaryIndustryDrawerAction"
      }
    },
    {
      label: "Name",
      field: "name",
      renderAs: import_stack9_sdk7.ColumnConfigurationRenderAs.Text,
      value: "{{name}}"
    },
    {
      label: "Is active",
      field: "is_active",
      renderAs: import_stack9_sdk7.ColumnConfigurationRenderAs.EnumTags,
      value: "{{is_active}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    }
  ];
  const formFields = [
    { field: "code", colSize: 8 },
    { field: "name", colSize: 8 },
    { field: "is_active", colSize: 8 },
    { field: "lang_set", colSize: 24 }
  ];
  const { queryService } = (0, import_stack9_react3.useStack9)();
  const { schema, isValidating } = (0, import_stack9_react3.useEntitySchema)(entityNames.INDUSTRY);
  const [isOpen, setIsOpen] = (0, import_react9.useState)(false);
  const refresh = useRefreshList5();
  const handleSave = (0, import_react9.useCallback)(
    (values) => __async(void 0, null, function* () {
      var _a, _b;
      yield queryService.runNamedQuery(screenKey, "updateIndustry", {
        vars: {
          code: values.code,
          name: values.name,
          is_active: (_a = values.is_active) != null ? _a : null,
          lang_set: (_b = JSON.stringify(values.lang_set)) != null ? _b : null,
          id
        }
      });
      onClose == null ? void 0 : onClose();
      refresh();
    }),
    [id, onClose, queryService, refresh, screenKey]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(
    S9Form6,
    {
      isValidating,
      onFinish: handleSave,
      loading: isValidating,
      form,
      initialValues,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
          S9Drawer10.Header,
          {
            title: "Edit industry",
            showCancel: true,
            extra: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
              S9EntityActions6,
              {
                showDelete: true,
                onDeleted: onClose,
                entityKey: entityNames.INDUSTRY,
                entityId: id,
                form
              }
            )
          }
        ),
        /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(S9Drawer10.Content, { children: schema && /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(import_jsx_runtime15.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
            S9Fieldset4,
            {
              context: { id: void 0 },
              schema,
              formFields
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
            ListTable,
            {
              queryVars: { industryId: id },
              queryName: "getsecondaryindustriesbyindustry",
              title: "Secondary industries",
              columns,
              children: /* @__PURE__ */ (0, import_jsx_runtime15.jsxs)(
                "div",
                {
                  style: {
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: "15px"
                  },
                  children: [
                    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
                      SecondaryIndustryDrawer,
                      {
                        initialValues: { industry_id: id },
                        open: isOpen,
                        onClose: () => {
                          setIsOpen(false);
                          refresh();
                        }
                      }
                    ),
                    /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(Space, { children: /* @__PURE__ */ (0, import_jsx_runtime15.jsx)(
                      Button,
                      {
                        label: "Create",
                        onClick: () => setIsOpen(true),
                        type: "primary"
                      }
                    ) })
                  ]
                }
              )
            }
          )
        ] }) })
      ]
    }
  );
};

// src/components/IndustryDrawer/IndustryDrawer.tsx
var import_jsx_runtime16 = require("react/jsx-runtime");
var { ScreenProvider: ScreenProvider4 } = ui12;
var S9Drawer12 = ui12.S9Drawer.default;
var IndustryDrawer = ({
  id,
  open,
  onClose,
  initialValues
}) => /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(ScreenProvider4, { relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */, children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(S9Drawer12, { open, width: 650, onClose, closable: true, children: /* @__PURE__ */ (0, import_jsx_runtime16.jsx)(IndustryForm, { initialValues, id, onClose }) }) });

// src/components/ListViewCustomComponents/IndustryDrawerAction.tsx
var import_jsx_runtime17 = require("react/jsx-runtime");
var S9Button11 = ui13.S9Button.default;
var { useRefreshList: useRefreshList6, Space: Space2 } = ui13;
var config5 = {
  name: "IndustryDrawerAction",
  icon: "",
  category: import_stack9_sdk8.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk8.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var IndustryDrawerAction = ({ row }) => {
  const [isOpen, setIsOpen] = (0, import_react10.useState)(false);
  const refresh = useRefreshList6();
  const formFields = [
    { field: "code" },
    { field: "name" },
    { field: "is_active" },
    { field: "lang_set" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(import_jsx_runtime17.Fragment, { children: row.id ? /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)(import_jsx_runtime17.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      S9Button11,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setIsOpen(true),
        label: `${row.code}`
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      IndustryDrawer,
      {
        initialValues: row,
        id: row.id,
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    )
  ] }) : /* @__PURE__ */ (0, import_jsx_runtime17.jsxs)(import_jsx_runtime17.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(Space2, { children: /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      S9Button11,
      {
        label: "Create",
        onClick: () => setIsOpen(true),
        type: "primary"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime17.jsx)(
      EntityDrawer,
      {
        title: "Industry",
        id: row == null ? void 0 : row.id,
        open: isOpen,
        formFields,
        initialValues: row,
        onClose: () => {
          setIsOpen(false);
          refresh();
        },
        screenQueryName: "getIndustryDetails" /* GetIndustryDetails */,
        entityName: entityNames.INDUSTRY,
        editRoute: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */,
        relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */
      }
    )
  ] }) });
};
var IndustryDrawerAction_default = IndustryDrawerAction;

// src/components/ListViewCustomComponents/OccupationDrawerAction.tsx
var OccupationDrawerAction_exports = {};
__export(OccupationDrawerAction_exports, {
  config: () => config6,
  default: () => OccupationDrawerAction_default
});
var import_react11 = require("react");
var import_stack9_sdk9 = require("@april9/stack9-sdk");
var ui14 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime18 = require("react/jsx-runtime");
var S9Button13 = ui14.S9Button.default;
var { useRefreshList: useRefreshList7, Space: Space3 } = ui14;
var config6 = {
  name: "OccupationDrawerAction",
  icon: "",
  category: import_stack9_sdk9.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk9.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var OccupationDrawerAction = ({ row }) => {
  const [isOpen, setIsOpen] = (0, import_react11.useState)(false);
  const refresh = useRefreshList7();
  const formFields = [
    { field: "code" },
    { field: "name" },
    { field: "is_active" },
    { field: "lang_set" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime18.jsxs)(import_jsx_runtime18.Fragment, { children: [
    row.id ? /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      S9Button13,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setIsOpen(true),
        label: `${row.code}`
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(Space3, { children: /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      S9Button13,
      {
        label: "Create",
        onClick: () => setIsOpen(true),
        type: "primary"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime18.jsx)(
      EntityDrawer,
      {
        title: "occupation",
        id: row == null ? void 0 : row.id,
        open: isOpen,
        formFields,
        initialValues: row,
        onClose: () => {
          setIsOpen(false);
          refresh();
        },
        screenQueryName: "getOccupationDetails" /* GetOccupationDetails */,
        entityName: entityNames.OCCUPATION,
        editRoute: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */,
        relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */
      }
    )
  ] });
};
var OccupationDrawerAction_default = OccupationDrawerAction;

// src/components/ListViewCustomComponents/OptionSetAction.tsx
var OptionSetAction_exports = {};
__export(OptionSetAction_exports, {
  config: () => config7,
  default: () => OptionSetAction_default
});
var import_react12 = require("react");
var import_stack9_sdk10 = require("@april9/stack9-sdk");
var ui15 = __toESM(require("@april9/stack9-ui"));

// src/components/OptionSetDrawer/OptionSetDrawer.tsx
var import_jsx_runtime19 = require("react/jsx-runtime");
var OptionSetDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "sequence", colSize: 12 },
    { field: "is_correct", colSize: 12 },
    { field: "option" },
    { field: "negative_response" },
    { field: "lang_set" },
    { field: "negative_response_lang_set" },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime19.jsx)(
    EntityDrawer,
    {
      title: "option set",
      formFields,
      initialValues,
      id,
      open,
      onClose,
      screenQueryName: "getOptionSetDetails" /* GetOptionSetDetails */,
      entityName: entityNames.OPTION_SET,
      editRoute: "/ccfg/requirement-detail/:id" /* RequirementDetail */,
      relativePath: "/ccfg/requirement-detail/:id" /* RequirementDetail */
    }
  );
};

// src/components/ListViewCustomComponents/OptionSetAction.tsx
var import_jsx_runtime20 = require("react/jsx-runtime");
var S9Button15 = ui15.S9Button.default;
var { useRefreshList: useRefreshList8 } = ui15;
var config7 = {
  name: "OptionSetAction",
  icon: "",
  category: import_stack9_sdk10.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk10.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var OptionSetAction = ({ row }) => {
  const refresh = useRefreshList8();
  const [drawerState, setDrawerState] = (0, import_react12.useState)({ open: false });
  return /* @__PURE__ */ (0, import_jsx_runtime20.jsxs)(import_jsx_runtime20.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      S9Button15,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setDrawerState({ id: row.id, open: true }),
        label: `${row.option}`
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime20.jsx)(
      OptionSetDrawer,
      {
        initialValues: row,
        id: row.id,
        open: drawerState.open,
        onClose: () => {
          setDrawerState({ id: void 0, open: false });
          refresh();
        }
      }
    )
  ] });
};
var OptionSetAction_default = OptionSetAction;

// src/components/ListViewCustomComponents/PriceRuleDrawerAction.tsx
var PriceRuleDrawerAction_exports = {};
__export(PriceRuleDrawerAction_exports, {
  config: () => config8,
  default: () => PriceRuleDrawerAction_default
});
var import_react13 = require("react");
var import_stack9_sdk11 = require("@april9/stack9-sdk");
var ui16 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime21 = require("react/jsx-runtime");
var S9Button17 = ui16.S9Button.default;
var { useRefreshList: useRefreshList9, Space: Space4 } = ui16;
var config8 = {
  name: "PriceRuleDrawerAction",
  icon: "",
  category: import_stack9_sdk11.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk11.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var PriceRuleDrawerAction = ({ row }) => {
  const [isOpen, setIsOpen] = (0, import_react13.useState)(false);
  const refresh = useRefreshList9();
  const formFields = [
    { field: "organisation_size" },
    { field: "currency" },
    { field: "amount", prefix: "$", colSize: 6 },
    { field: "period_start_dt" },
    { field: "period_end_dt" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime21.jsxs)(import_jsx_runtime21.Fragment, { children: [
    row.id ? /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
      S9Button17,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setIsOpen(true),
        label: `${row.organisation_size}`
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(Space4, { children: /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
      S9Button17,
      {
        label: "Create",
        onClick: () => setIsOpen(true),
        type: "primary"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime21.jsx)(
      EntityDrawer,
      {
        title: "price rule",
        id: row == null ? void 0 : row.id,
        open: isOpen,
        formFields,
        initialValues: row,
        onClose: () => {
          setIsOpen(false);
          refresh();
        },
        screenQueryName: "getPriceRuleDetails" /* GetPriceRuleDetails */,
        entityName: entityNames.PRICE_RULE,
        editRoute: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */,
        relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */
      }
    )
  ] });
};
var PriceRuleDrawerAction_default = PriceRuleDrawerAction;

// src/components/ListViewCustomComponents/PromoCodeDrawerAction.tsx
var PromoCodeDrawerAction_exports = {};
__export(PromoCodeDrawerAction_exports, {
  config: () => config9,
  default: () => PromoCodeDrawerAction_default
});
var import_react14 = require("react");
var import_stack9_sdk12 = require("@april9/stack9-sdk");
var ui17 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime22 = require("react/jsx-runtime");
var S9Button19 = ui17.S9Button.default;
var { useRefreshList: useRefreshList10, Space: Space5 } = ui17;
var config9 = {
  name: "PromoCodeDrawerAction",
  icon: "",
  category: import_stack9_sdk12.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk12.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var PromoCodeDrawerAction = ({ row }) => {
  const [isOpen, setIsOpen] = (0, import_react14.useState)(false);
  const refresh = useRefreshList10();
  const formFields = [
    { field: "campaign_name" },
    { field: "code" },
    { field: "start_dt" },
    { field: "end_dt" },
    { field: "discount_percentage" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime22.jsxs)(import_jsx_runtime22.Fragment, { children: [
    row.id ? /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
      S9Button19,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setIsOpen(true),
        label: `${row.code}`
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(Space5, { children: /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
      S9Button19,
      {
        label: "Create",
        onClick: () => setIsOpen(true),
        type: "primary"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime22.jsx)(
      EntityDrawer,
      {
        title: "Promo code",
        id: row == null ? void 0 : row.id,
        open: isOpen,
        formFields,
        initialValues: row,
        onClose: () => {
          setIsOpen(false);
          refresh();
        },
        screenQueryName: "getPromoCodeDetails" /* GetPromoCodeDetails */,
        entityName: entityNames.PROMO_CODE,
        editRoute: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */,
        relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */
      }
    )
  ] });
};
var PromoCodeDrawerAction_default = PromoCodeDrawerAction;

// src/components/ListViewCustomComponents/SecondaryIndustryDrawerAction.tsx
var SecondaryIndustryDrawerAction_exports = {};
__export(SecondaryIndustryDrawerAction_exports, {
  config: () => config10,
  default: () => SecondaryIndustryDrawerAction_default
});
var import_react15 = require("react");
var import_stack9_sdk13 = require("@april9/stack9-sdk");
var ui18 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime23 = require("react/jsx-runtime");
var S9Button21 = ui18.S9Button.default;
var { useRefreshList: useRefreshList11 } = ui18;
var config10 = {
  name: "SecondaryIndustryDrawerAction",
  icon: "",
  category: import_stack9_sdk13.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk13.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var SecondaryIndustryDrawerAction = ({
  row
}) => {
  const [isOpen, setIsOpen] = (0, import_react15.useState)(false);
  const refresh = useRefreshList11();
  return /* @__PURE__ */ (0, import_jsx_runtime23.jsxs)(import_jsx_runtime23.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      S9Button21,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setIsOpen(true),
        label: `${row.code}`
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime23.jsx)(
      SecondaryIndustryDrawer,
      {
        initialValues: row,
        id: row == null ? void 0 : row.id,
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    )
  ] });
};
var SecondaryIndustryDrawerAction_default = SecondaryIndustryDrawerAction;

// src/components/ListViewCustomComponents/SurveyQuestionDrawerAction.tsx
var SurveyQuestionDrawerAction_exports = {};
__export(SurveyQuestionDrawerAction_exports, {
  config: () => config11,
  default: () => SurveyQuestionDrawerAction_default
});
var import_react16 = require("react");
var import_stack9_sdk14 = require("@april9/stack9-sdk");
var ui19 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime24 = require("react/jsx-runtime");
var S9Button23 = ui19.S9Button.default;
var { useRefreshList: useRefreshList12, Space: Space6 } = ui19;
var config11 = {
  name: "SurveyQuestionDrawerAction",
  icon: "",
  category: import_stack9_sdk14.S9ComponentConfigCategory.GENERAL,
  version: import_stack9_sdk14.S9ComponentConfigRelease.ALPHA,
  hasState: true,
  allowChildren: false,
  props: []
};
var SurveyQuestionDrawerAction = ({
  row
}) => {
  const [isOpen, setIsOpen] = (0, import_react16.useState)(false);
  const refresh = useRefreshList12();
  const formFields = [
    { field: "code", colSize: 12, isRequired: false },
    { field: "sequence", colSize: 12 },
    { field: "question" },
    { field: "lang_set" },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime24.jsxs)(import_jsx_runtime24.Fragment, { children: [
    row.id ? /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
      S9Button23,
      {
        type: "link",
        style: { width: "100%", textAlign: "left" },
        onClick: () => setIsOpen(true),
        label: `${row.code}`
      }
    ) : /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(Space6, { children: /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
      S9Button23,
      {
        label: "Create",
        onClick: () => setIsOpen(true),
        type: "primary"
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime24.jsx)(
      EntityDrawer,
      {
        title: "survey question",
        id: row == null ? void 0 : row.id,
        open: isOpen,
        formFields,
        initialValues: row,
        onClose: () => {
          setIsOpen(false);
          refresh();
        },
        screenQueryName: "getSurveyQuestionDetails" /* GetSurveyQuestionDetails */,
        entityName: entityNames.SURVEY_QUESTION,
        editRoute: "/ccfg/country-template-detail/:id" /* CountryTemplateDetail */,
        relativePath: "/ccfg/country-template-detail/:id" /* CountryTemplateDetail */
      }
    )
  ] });
};
var SurveyQuestionDrawerAction_default = SurveyQuestionDrawerAction;

// src/pages/Account/Index.tsx
var import_stack9_react4 = require("@april9/stack9-react");
var ui21 = __toESM(require("@april9/stack9-ui"));

// src/pages/Account/AccountDetail.tsx
var ui20 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime25 = require("react/jsx-runtime");
var Fieldset = ui20.S9Fieldset.default;
var S9PageSection4 = ui20.S9PageSection.default;
var AccountDetail = ({ schema }) => {
  const fields = [
    {
      field: "account_number",
      colSize: 12
    },
    {
      field: "default_country",
      colSize: 6
    },
    {
      field: "is_active",
      colSize: 6
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(S9PageSection4, { title: "Account details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime25.jsx)(
    Fieldset,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var AccountDetail_default = AccountDetail;

// src/pages/Account/AccountOrganisations.tsx
var import_stack9_sdk15 = require("@april9/stack9-sdk");
var import_jsx_runtime26 = require("react/jsx-runtime");
var AccountOrganisations = ({ id }) => /* @__PURE__ */ (0, import_jsx_runtime26.jsx)(
  ListTable,
  {
    queryVars: { accountId: id },
    queryName: "getorganisationbyaccount",
    title: "Organisations",
    columns: [
      {
        label: "Organisation number",
        field: "org_number",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.Text,
        value: "{{org_number}}",
        options: {
          linkProp: "/crm/organisation-detail/{{id}}"
        }
      },
      {
        label: "Registered name",
        field: "registered_name",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.Text,
        value: "{{registered_name}}"
      },
      {
        label: "Main industry",
        field: "main_industry_name",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.Text,
        value: "{{[main_industry_code, main_industry_name].filter(Boolean).join(' - ')}}"
      },
      {
        label: "Secondary industry",
        field: "secondary_industry_name",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.Text,
        value: "{{[secondary_industry_code, secondary_industry_name].filter(Boolean).join(' - ')}}"
      },
      {
        label: "Partner name",
        field: "partner.registered_name",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.Text,
        value: "{{partner.registered_name}}"
      },
      {
        label: "Payment waiver end date",
        field: "payment_waiver_end_dt",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.Date,
        value: "{{payment_waiver_end_dt}}"
      },
      {
        label: "Is active",
        field: "is_active",
        renderAs: import_stack9_sdk15.ColumnConfigurationRenderAs.EnumTags,
        value: "{{is_active}}",
        options: {
          enumColor: {
            Yes: "green",
            No: "red"
          }
        }
      }
    ]
  }
);
var AccountOrganisations_default = AccountOrganisations;

// src/pages/Account/AccountUsers.tsx
var import_stack9_sdk16 = require("@april9/stack9-sdk");
var import_jsx_runtime27 = require("react/jsx-runtime");
var AccountUsers = ({ id }) => /* @__PURE__ */ (0, import_jsx_runtime27.jsx)(
  ListTable,
  {
    queryVars: { accountId: id },
    queryName: "getusersbyaccountlist",
    title: "Users",
    columns: [
      {
        label: "User name",
        field: "username",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{username}}",
        options: {
          linkProp: "/crm/user/{{id}}"
        }
      },
      {
        label: "First name",
        field: "first_name",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{first_name}}"
      },
      {
        label: "Last name",
        field: "last_name",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{last_name}}"
      },
      {
        label: "Email",
        field: "email",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{email}}"
      },
      {
        label: "Phone",
        field: "phone",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{phone}}"
      },
      {
        label: "Position",
        field: "position",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{position}}"
      },
      {
        label: "Default language",
        field: "default_language",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{default_language}}"
      },
      {
        label: "User groups",
        field: "user_groups",
        value: "{{user_groups.map(item=>item.name)}}",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.EnumTags
      },
      {
        label: "Invited by",
        field: "invited_by_user",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Text,
        value: "{{invited_by_user.full_name}}"
      },
      {
        label: "Created at",
        field: "_created_at",
        renderAs: import_stack9_sdk16.ColumnConfigurationRenderAs.Date,
        value: "{{_created_at}}",
        options: {
          noTime: true
        }
      }
    ]
  }
);
var AccountUsers_default = AccountUsers;

// src/pages/Account/Index.tsx
var import_jsx_runtime28 = require("react/jsx-runtime");
var S9CustomEntityFormPage2 = ui21.S9CustomEntityFormPage.default;
var Index = () => {
  const { schema } = (0, import_stack9_react4.useEntitySchema)(entityNames.ACCOUNT);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react4.useRouteAndQueryParams)();
  return /* @__PURE__ */ (0, import_jsx_runtime28.jsxs)(
    S9CustomEntityFormPage2,
    {
      entityKey: entityNames.ACCOUNT,
      screenQueryName: "getAccountDetails" /* GetAccountDetails */,
      entityId: +id,
      title: (data) => `Account #${data == null ? void 0 : data.id}`,
      cancelLink: "/crm/account/list" /* AccountList */,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(AccountDetail_default, { schema }),
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(AccountOrganisations_default, { id: +id }),
        /* @__PURE__ */ (0, import_jsx_runtime28.jsx)(AccountUsers_default, { id: +id })
      ]
    }
  );
};
var Index_default = Index;

// src/pages/Application/ApplicationDetail.tsx
var import_react17 = require("react");
var import_stack9_react5 = require("@april9/stack9-react");
var import_stack9_sdk18 = require("@april9/stack9-sdk");
var ui24 = __toESM(require("@april9/stack9-ui"));

// src/pages/Application/ApplicationForm.tsx
var ui22 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime29 = require("react/jsx-runtime");
var Fieldset2 = ui22.S9Fieldset.default;
var S9PageSection6 = ui22.S9PageSection.default;
var ApplicationForm = ({ schema }) => {
  const fields = [
    {
      field: "organisation_id",
      colSize: 12
    },
    {
      field: "country",
      colSize: 12
    },
    {
      field: "certification_number",
      colSize: 8
    },
    {
      field: "partner_id",
      colSize: 16
    },
    {
      field: "organisation_size",
      colSize: 6
    },
    {
      field: "submitted_by_id",
      colSize: 6
    },
    {
      field: "submitted_dt",
      colSize: 6
    },
    {
      field: "award_dt",
      colSize: 6
    },
    {
      field: "expiry_dt",
      colSize: 6
    }
  ];
  const paymentFields = [
    {
      field: "payment_status",
      colSize: 6
    },
    {
      field: "payable_amount",
      prefix: "$",
      colSize: 6
    },
    {
      field: "promo_code",
      colSize: 6
    },
    {
      field: "payment_reference",
      colSize: 6
    },
    {
      field: "payment_dt",
      colSize: 6
    },
    {
      field: "payment_gateway_response"
    }
  ];
  const assessorFields = [
    {
      field: "assessed_by_id",
      colSize: 12
    },
    {
      field: "request_info_dt",
      colSize: 12
    },
    {
      field: "request_info_assessor_note"
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(import_jsx_runtime29.Fragment, { children: schema && /* @__PURE__ */ (0, import_jsx_runtime29.jsxs)(import_jsx_runtime29.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(S9PageSection6, { title: "", size: "sm", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
      Fieldset2,
      {
        context: { id: void 0 },
        schema,
        formFields: fields
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(S9PageSection6, { title: "Payment details", size: "sm", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
      Fieldset2,
      {
        context: { id: void 0 },
        schema,
        formFields: paymentFields
      }
    ) }),
    /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(S9PageSection6, { title: "Assessor details", size: "sm", children: /* @__PURE__ */ (0, import_jsx_runtime29.jsx)(
      Fieldset2,
      {
        context: { id: void 0 },
        schema,
        formFields: assessorFields
      }
    ) })
  ] }) });
};
var ApplicationForm_default = ApplicationForm;

// src/pages/Application/StaffBreakdownForm.tsx
var import_stack9_sdk17 = require("@april9/stack9-sdk");
var ui23 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime30 = require("react/jsx-runtime");
var Fieldset3 = ui23.S9Fieldset.default;
var S9Row4 = ui23.S9Row.default;
var S9Col4 = ui23.S9Col.default;
var S9PageSection8 = ui23.S9PageSection.default;
var StaffBreakdownForm = ({ schema, applicationId }) => {
  const fields = [
    { field: "employee_count", colSize: 4 },
    { field: "employee_count_1", colSize: 4 },
    { field: "employee_count_2", colSize: 4 },
    { field: "employee_count_3", colSize: 5 },
    { field: "apprentice_count", colSize: 4 },
    { field: "apprentice_count_1", colSize: 4 },
    { field: "apprentice_count_2", colSize: 4 },
    { field: "apprentice_count_3", colSize: 4 }
  ];
  const commonColumns = [
    {
      label: "Female",
      field: "total_female",
      renderAs: import_stack9_sdk17.ColumnConfigurationRenderAs.Text,
      value: "{{total_female}}"
    },
    {
      label: "Male",
      field: "total_male",
      renderAs: import_stack9_sdk17.ColumnConfigurationRenderAs.Text,
      value: "{{total_male}}"
    },
    {
      label: "Other",
      field: "total_other",
      renderAs: import_stack9_sdk17.ColumnConfigurationRenderAs.Text,
      value: "{{total_other}}"
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(import_jsx_runtime30.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(S9PageSection8, { title: "Staff Counts", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(import_jsx_runtime30.Fragment, { children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
      Fieldset3,
      {
        context: { id: void 0 },
        schema,
        formFields: fields
      }
    ) }) }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(S9Row4, { gutter: 24, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(S9Col4, { md: 24, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
      ListTable,
      {
        queryVars: {
          applicationId
        },
        columns: [
          {
            label: "Age range",
            field: "age_range",
            renderAs: import_stack9_sdk17.ColumnConfigurationRenderAs.Text,
            value: "{{age_range}}"
          },
          ...commonColumns
        ],
        title: "Staff age breakdown",
        queryName: "getstaffagebreakdownlistbyapplication"
      }
    ) }) }),
    /* @__PURE__ */ (0, import_jsx_runtime30.jsxs)(S9Row4, { gutter: 24, children: [
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(S9Col4, { md: 12, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
        ListTable,
        {
          queryVars: {
            applicationId
          },
          columns: [
            {
              label: "Occupation",
              field: "occupation_name",
              renderAs: import_stack9_sdk17.ColumnConfigurationRenderAs.Text,
              value: "{{occupation_name}}"
            },
            ...commonColumns
          ],
          title: "Apprentice occupation breakdown",
          queryName: "getapprenticeoccupationbreakdownbyapplication"
        }
      ) }),
      /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(S9Col4, { md: 12, children: /* @__PURE__ */ (0, import_jsx_runtime30.jsx)(
        ListTable,
        {
          queryVars: {
            applicationId
          },
          columns: [
            {
              label: "Age range",
              field: "age_range",
              renderAs: import_stack9_sdk17.ColumnConfigurationRenderAs.Text,
              value: "{{age_range}}"
            },
            ...commonColumns
          ],
          title: "Apprentice age breakdown",
          queryName: "getapprenticeagebreakdownbyapplication"
        }
      ) })
    ] })
  ] });
};
var StaffBreakdownForm_default = StaffBreakdownForm;

// src/pages/Application/ApplicationDetail.tsx
var import_jsx_runtime31 = require("react/jsx-runtime");
var S9CustomEntityFormPage4 = ui24.S9CustomEntityFormPage.default;
var S9Tabs2 = ui24.S9Tabs.default;
var Index2 = () => {
  const { schema } = (0, import_stack9_react5.useEntitySchema)(entityNames.APPLICATION);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react5.useRouteAndQueryParams)();
  const applicationId = (0, import_stack9_sdk18.ern)(+(0, import_stack9_sdk18.ep)(id));
  const items = (0, import_react17.useMemo)(
    () => [
      {
        label: "Details",
        key: "details",
        children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(ApplicationForm_default, { schema })
      },
      {
        label: "Staff breakdown",
        key: "staff_breakdown",
        children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(StaffBreakdownForm_default, { schema, applicationId })
      },
      {
        label: "Response",
        key: "response",
        children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
          ListTable,
          {
            searchFields: [
              "id",
              "requirement_code",
              "section_code",
              "response_option",
              "response_text",
              "status",
              "assessor_comment"
            ],
            showFilter: true,
            columns: [
              {
                label: "ID",
                value: "{{id}}",
                field: "id",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.CustomComponent,
                options: {
                  customComponentName: "ApplicationResponseAction"
                }
              },
              {
                label: "Requirement code",
                value: "{{requirement_code}}",
                field: "requirement_code",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text
              },
              {
                label: "Section code",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text,
                value: "{{section_code}}",
                field: "section_code"
              },
              {
                label: "Response option",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text,
                value: "{{response_option}}",
                field: "response_option"
              },
              {
                label: "Response text",
                value: "{{response_text}}",
                field: "response_text",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text
              },
              {
                label: "Status",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text,
                value: "{{status}}",
                field: "status"
              },
              {
                label: "Assessor comment",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text,
                value: "{{assessor_comment}}",
                field: "assessor_comment"
              }
            ],
            queryName: "getresponsebyapplication",
            queryVars: {
              applicationId
            },
            title: "Response"
          }
        )
      },
      {
        label: "Survey",
        key: "survey",
        children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
          ListTable,
          {
            columns: [
              {
                label: "Survey question",
                field: "code",
                value: "{{code}}",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text
              },
              {
                label: "Response",
                field: "response",
                value: "{{response}}",
                renderAs: import_stack9_sdk18.ColumnConfigurationRenderAs.Text
              }
            ],
            queryName: "getsurveybyapplication",
            queryVars: {
              applicationId
            },
            title: "Survey"
          }
        )
      }
    ],
    [applicationId, schema]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(
    S9CustomEntityFormPage4,
    {
      entityKey: entityNames.APPLICATION,
      screenQueryName: "getApplicationDetails" /* GetApplicationDetails */,
      entityId: applicationId,
      title: (data) => `Application #${data == null ? void 0 : data.id}`,
      cancelLink: "/app/application/list" /* ApplicationList */,
      children: /* @__PURE__ */ (0, import_jsx_runtime31.jsx)(S9Tabs2, { items })
    }
  );
};
var ApplicationDetail_default = Index2;

// src/pages/BaseRequirement/BaseRequirementDetail.tsx
var import_stack9_react6 = require("@april9/stack9-react");
var ui27 = __toESM(require("@april9/stack9-ui"));

// src/pages/BaseRequirement/BaseOptionSetDetail.tsx
var import_react18 = require("react");
var import_stack9_sdk19 = require("@april9/stack9-sdk");
var ui25 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime32 = require("react/jsx-runtime");
var Button2 = ui25.S9Button.default;
var { Space: Space7, useRefreshList: useRefreshList13 } = ui25;
var BaseOptionSetDetail = ({ id }) => {
  const [isOpen, setIsOpen] = (0, import_react18.useState)(false);
  const refresh = useRefreshList13();
  return /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
    ListTable,
    {
      queryVars: {
        baseRequirement: id
      },
      queryName: "getbaseoptionsetbybaserequirement",
      title: "Base option set",
      columns: [
        {
          label: "Sequence",
          field: "sequence",
          renderAs: import_stack9_sdk19.ColumnConfigurationRenderAs.Text,
          value: "{{sequence}}"
        },
        {
          label: "Option",
          field: "option",
          renderAs: import_stack9_sdk19.ColumnConfigurationRenderAs.CustomComponent,
          options: {
            customComponentName: "BaseOptionSetAction"
          },
          value: "{{option}}"
        },
        {
          label: "Is correct",
          field: "is_correct",
          renderAs: import_stack9_sdk19.ColumnConfigurationRenderAs.EnumTags,
          value: "{{is_correct}}",
          options: {
            enumColor: {
              Yes: "green",
              No: "red"
            }
          }
        },
        {
          label: "Negative response",
          field: "negative_response",
          renderAs: import_stack9_sdk19.ColumnConfigurationRenderAs.Text,
          value: "{{negative_response}}"
        },
        {
          label: "Is active",
          field: "is_active",
          renderAs: import_stack9_sdk19.ColumnConfigurationRenderAs.EnumTags,
          value: "{{is_active}}",
          options: {
            enumColor: {
              Yes: "green",
              No: "red"
            }
          }
        }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime32.jsxs)(
        "div",
        {
          style: { display: "flex", justifyContent: "end", marginBottom: "15px" },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
              BaseOptionSetDrawer,
              {
                initialValues: { base_requirement_id: id },
                open: isOpen,
                onClose: () => {
                  setIsOpen(false);
                  refresh();
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(Space7, { children: /* @__PURE__ */ (0, import_jsx_runtime32.jsx)(
              Button2,
              {
                label: "Create",
                onClick: () => setIsOpen(true),
                type: "primary"
              }
            ) })
          ]
        }
      )
    }
  );
};
var BaseOptionSetDetail_default = BaseOptionSetDetail;

// src/pages/BaseRequirement/BaseRequirementForm.tsx
var ui26 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime33 = require("react/jsx-runtime");
var Fieldset4 = ui26.S9Fieldset.default;
var S9PageSection10 = ui26.S9PageSection.default;
var BaseRequirementForm = ({ schema }) => {
  const fields = [
    {
      field: "summary"
    },
    {
      field: "code",
      colSize: 9
    },
    {
      field: "question_type",
      colSize: 9
    },
    {
      field: "sequence",
      colSize: 6
    },
    {
      field: "requirement_section_id",
      label: "Requirement section"
    },
    {
      field: "question"
    },
    {
      field: "required_document_types"
    },
    {
      field: "allow_text_area",
      colSize: 6
    },
    {
      field: "is_mandatory",
      colSize: 6
    },
    {
      field: "preserve_for_renewal",
      colSize: 6
    },
    {
      field: "is_active",
      colSize: 6
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(S9PageSection10, { title: "Base requirement details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime33.jsx)(
    Fieldset4,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var BaseRequirementForm_default = BaseRequirementForm;

// src/pages/BaseRequirement/BaseRequirementDetail.tsx
var import_jsx_runtime34 = require("react/jsx-runtime");
var S9CustomEntityFormPage6 = ui27.S9CustomEntityFormPage.default;
var Index3 = () => {
  const { schema } = (0, import_stack9_react6.useEntitySchema)(entityNames.BASE_REQUIREMENT);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react6.useRouteAndQueryParams)();
  return /* @__PURE__ */ (0, import_jsx_runtime34.jsxs)(
    S9CustomEntityFormPage6,
    {
      entityKey: entityNames.BASE_REQUIREMENT,
      screenQueryName: "getBaseRequirementDetails" /* GetBaseRequirementDetails */,
      entityId: +id,
      title: (data) => `Base requirement #${data == null ? void 0 : data.id}`,
      cancelLink: "/ccfg/base-requirement/list" /* BaseRequirementList */,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(BaseRequirementForm_default, { schema }),
        /* @__PURE__ */ (0, import_jsx_runtime34.jsx)(BaseOptionSetDetail_default, { id: +id })
      ]
    }
  );
};
var BaseRequirementDetail_default = Index3;

// src/pages/CountrySetup/CountrySetupDetail.tsx
var import_react19 = require("react");
var import_stack9_react7 = require("@april9/stack9-react");
var import_stack9_sdk24 = require("@april9/stack9-sdk");
var ui30 = __toESM(require("@april9/stack9-ui"));

// src/pages/CountrySetup/CountrySetupForm.tsx
var ui28 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime35 = require("react/jsx-runtime");
var Fieldset5 = ui28.S9Fieldset.default;
var S9PageSection12 = ui28.S9PageSection.default;
var CountrySetupForm = ({ schema }) => {
  const fields = [
    {
      field: "country",
      colSize: 8,
      isReadOnly: true
    },
    {
      field: "last_published_dt",
      colSize: 8,
      isReadOnly: true
    },
    {
      field: "last_published_by_id",
      colSize: 8,
      isReadOnly: true
    },
    {
      field: "employee_count_1_question",
      colSize: 8
    },
    {
      field: "employee_count_2_question",
      colSize: 8
    },
    {
      field: "employee_count_3_question",
      colSize: 8
    },
    {
      field: "apprentice_count_1_question",
      colSize: 8
    },
    {
      field: "apprentice_count_2_question",
      colSize: 8
    },
    {
      field: "apprentice_count_3_question",
      colSize: 8
    },
    {
      field: "certificate_logo",
      colSize: 12
    },
    {
      field: "download_seals",
      colSize: 12
    },
    {
      field: "certificate_signature",
      colSize: 12
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(S9PageSection12, { title: "", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime35.jsx)(
    Fieldset5,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var CountrySetupForm_default = CountrySetupForm;

// src/pages/CountrySetup/PriceRuleInfo.tsx
var import_stack9_sdk20 = require("@april9/stack9-sdk");
var import_jsx_runtime36 = require("react/jsx-runtime");
var Drawer = PriceRuleDrawerAction_exports.default;
var PriceRuleInfo = ({ countrySetupId, initialValues }) => {
  const columns = [
    {
      label: "Org size",
      field: "organisation_size",
      renderAs: import_stack9_sdk20.ColumnConfigurationRenderAs.CustomComponent,
      value: "{{organisation_size}}",
      options: {
        customComponentName: "PriceRuleDrawerAction"
      }
    },
    {
      label: "Currency",
      field: "currency",
      renderAs: import_stack9_sdk20.ColumnConfigurationRenderAs.Text,
      value: "{{currency}}"
    },
    {
      label: "Amount",
      field: "amount",
      renderAs: import_stack9_sdk20.ColumnConfigurationRenderAs.Decimal,
      value: "{{amount}}",
      options: {
        prefix: "$"
      }
    },
    {
      label: "Period start date",
      field: "period_start_dt",
      renderAs: import_stack9_sdk20.ColumnConfigurationRenderAs.Date,
      value: "{{period_start_dt}}",
      options: {
        noTime: true
      }
    },
    {
      label: "Period end date",
      field: "period_end_dt",
      renderAs: import_stack9_sdk20.ColumnConfigurationRenderAs.Date,
      value: "{{period_end_dt}}",
      options: {
        noTime: true
      }
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
    ListTable,
    {
      queryVars: { countrySetupId },
      columns,
      title: "Price rules",
      queryName: "getpriceruleslistbycountrysetup",
      children: /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime36.jsx)(Drawer, { row: __spreadValues({}, initialValues) })
        }
      )
    }
  );
};
var PriceRuleInfo_default = PriceRuleInfo;

// src/pages/CountrySetup/PromoCodeInfo.tsx
var import_stack9_sdk21 = require("@april9/stack9-sdk");
var import_jsx_runtime37 = require("react/jsx-runtime");
var Drawer2 = PromoCodeDrawerAction_exports.default;
var PromoCodeInfo = ({ countrySetupId, initialValues }) => {
  const columns = [
    {
      label: "Campaign name",
      field: "campaign_name",
      renderAs: import_stack9_sdk21.ColumnConfigurationRenderAs.CustomComponent,
      value: "{{campaign_name}}",
      options: {
        customComponentName: "PromoCodeDrawerAction"
      }
    },
    {
      label: "Code",
      field: "code",
      renderAs: import_stack9_sdk21.ColumnConfigurationRenderAs.Text,
      value: "{{code}}"
    },
    {
      label: "Start date",
      field: "start_dt",
      renderAs: import_stack9_sdk21.ColumnConfigurationRenderAs.Date,
      value: "{{start_dt}}",
      options: {
        noTime: true
      }
    },
    {
      label: "End date",
      field: "end_dt",
      renderAs: import_stack9_sdk21.ColumnConfigurationRenderAs.Date,
      value: "{{end_dt}}",
      options: {
        noTime: true
      }
    },
    {
      label: "Discount percentage",
      field: "discount_percentage",
      renderAs: import_stack9_sdk21.ColumnConfigurationRenderAs.Decimal,
      value: "{{discount_percentage}}"
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
    ListTable,
    {
      queryVars: { countrySetupId },
      columns,
      title: "Promo codes",
      queryName: "getpromocodebycountrysetup",
      children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime37.jsx)(Drawer2, { row: __spreadValues({}, initialValues) })
        }
      )
    }
  );
};
var PromoCodeInfo_default = PromoCodeInfo;

// src/pages/CountrySetup/ReferenceData.tsx
var ui29 = __toESM(require("@april9/stack9-ui"));

// src/pages/CountrySetup/IndustriesInfo.tsx
var import_stack9_sdk22 = require("@april9/stack9-sdk");
var import_jsx_runtime38 = require("react/jsx-runtime");
var Drawer3 = IndustryDrawerAction_exports.default;
var IndustriesInfo = ({ countrySetupId, initialValues }) => {
  const columns = [
    {
      label: "Code",
      field: "code",
      renderAs: import_stack9_sdk22.ColumnConfigurationRenderAs.CustomComponent,
      value: "{{code}}",
      options: {
        customComponentName: "IndustryDrawerAction"
      }
    },
    {
      label: "Name",
      field: "name",
      renderAs: import_stack9_sdk22.ColumnConfigurationRenderAs.Text,
      value: "{{name}}"
    },
    {
      label: "Is active",
      field: "is_active",
      renderAs: import_stack9_sdk22.ColumnConfigurationRenderAs.EnumTags,
      value: "{{is_active}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(
    ListTable,
    {
      queryVars: { countrySetupId },
      columns,
      title: "Industries",
      queryName: "getindustrieslistbycountrysetup",
      children: /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime38.jsx)(Drawer3, { row: __spreadValues({}, initialValues) })
        }
      )
    }
  );
};
var IndustriesInfo_default = IndustriesInfo;

// src/pages/CountrySetup/OccupationInfo.tsx
var import_stack9_sdk23 = require("@april9/stack9-sdk");
var import_jsx_runtime39 = require("react/jsx-runtime");
var Drawer4 = OccupationDrawerAction_exports.default;
var OccupationInfo = ({ countrySetupId, initialValues }) => {
  const columns = [
    {
      label: "Code",
      field: "code",
      renderAs: import_stack9_sdk23.ColumnConfigurationRenderAs.CustomComponent,
      value: "{{code}}",
      options: {
        customComponentName: "OccupationDrawerAction"
      }
    },
    {
      label: "Name",
      field: "name",
      renderAs: import_stack9_sdk23.ColumnConfigurationRenderAs.Text,
      value: "{{name}}"
    },
    {
      label: "Is active",
      field: "is_active",
      renderAs: import_stack9_sdk23.ColumnConfigurationRenderAs.EnumTags,
      value: "{{is_active}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
    ListTable,
    {
      queryVars: { countrySetupId },
      columns,
      title: "Occupations",
      queryName: "getoccupationlistbycountrysetup",
      children: /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime39.jsx)(Drawer4, { row: __spreadValues({}, initialValues) })
        }
      )
    }
  );
};
var OccupationInfo_default = OccupationInfo;

// src/pages/CountrySetup/ReferenceData.tsx
var import_jsx_runtime40 = require("react/jsx-runtime");
var S9PageSection14 = ui29.S9PageSection.default;
var S9Row6 = ui29.S9Row.default;
var S9Col6 = ui29.S9Col.default;
var ReferenceData = ({ countrySetupId }) => /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(S9PageSection14, { title: "", size: "sm", children: /* @__PURE__ */ (0, import_jsx_runtime40.jsxs)(S9Row6, { gutter: 24, children: [
  /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(S9Col6, { md: 12, children: /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
    OccupationInfo_default,
    {
      countrySetupId,
      initialValues: { country_setup_id: countrySetupId }
    }
  ) }),
  /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(S9Col6, { md: 12, children: /* @__PURE__ */ (0, import_jsx_runtime40.jsx)(
    IndustriesInfo_default,
    {
      countrySetupId,
      initialValues: { country_setup_id: countrySetupId }
    }
  ) })
] }) });
var ReferenceData_default = ReferenceData;

// src/pages/CountrySetup/CountrySetupDetail.tsx
var import_jsx_runtime41 = require("react/jsx-runtime");
var S9CustomEntityFormPage8 = ui30.S9CustomEntityFormPage.default;
var S9Tabs4 = ui30.S9Tabs.default;
var CountrySetupDetail = () => {
  const { schema } = (0, import_stack9_react7.useEntitySchema)(entityNames.COUNTRY_SETUP);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react7.useRouteAndQueryParams)();
  const countrySetupId = (0, import_stack9_sdk24.ern)(+(0, import_stack9_sdk24.ep)(id));
  const items = (0, import_react19.useMemo)(
    () => [
      {
        label: "Details",
        key: "details",
        children: /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(CountrySetupForm_default, { schema })
      },
      {
        label: "Pricing",
        key: "pricing",
        children: /* @__PURE__ */ (0, import_jsx_runtime41.jsxs)(import_jsx_runtime41.Fragment, { children: [
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
            PriceRuleInfo_default,
            {
              countrySetupId,
              initialValues: { country_setup_id: countrySetupId }
            }
          ),
          /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
            PromoCodeInfo_default,
            {
              countrySetupId,
              initialValues: { country_setup_id: countrySetupId }
            }
          )
        ] })
      },
      {
        label: "Reference data",
        key: "reference_data",
        children: /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(ReferenceData_default, { countrySetupId })
      }
    ],
    [countrySetupId, schema]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(
    S9CustomEntityFormPage8,
    {
      entityKey: entityNames.COUNTRY_SETUP,
      screenQueryName: "getCountrySetupDetails" /* GetCountrySetupDetails */,
      entityId: countrySetupId,
      title: (data) => `Country setup #${data == null ? void 0 : data.id}`,
      cancelLink: "/ccfg/country-setup/list" /* CountrySetupList */,
      children: /* @__PURE__ */ (0, import_jsx_runtime41.jsx)(S9Tabs4, { items })
    }
  );
};
var CountrySetupDetail_default = CountrySetupDetail;

// src/pages/CountryTemplate/CountryTemplateDetail.tsx
var import_react21 = require("react");
var import_stack9_react8 = require("@april9/stack9-react");
var ui32 = __toESM(require("@april9/stack9-ui"));

// src/pages/CountryTemplate/CountryTemplateForm.tsx
var ui31 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime42 = require("react/jsx-runtime");
var Fieldset6 = ui31.S9Fieldset.default;
var S9PageSection16 = ui31.S9PageSection.default;
var CountryTemplateForm = ({ schema }) => {
  const fields = [
    {
      field: "country",
      colSize: 8,
      isReadOnly: true
    },
    {
      field: "last_published_dt",
      colSize: 8,
      isReadOnly: true
    },
    {
      field: "last_published_by_id",
      colSize: 8,
      isReadOnly: true
    },
    {
      field: "printable_requirements",
      colSize: 12
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(S9PageSection16, { title: "", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime42.jsx)(
    Fieldset6,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var CountryTemplateForm_default = CountryTemplateForm;

// src/pages/CountryTemplate/RequirementInfo.tsx
var import_react20 = require("react");
var import_stack9_sdk25 = require("@april9/stack9-sdk");
var import_stack9_ui3 = require("@april9/stack9-ui");

// src/components/RequirementDrawer/RequirementDrawer.tsx
var import_jsx_runtime43 = require("react/jsx-runtime");
var RequirementDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "code", isRequired: false, isReadOnly: true, colSize: 8 },
    { field: "question_type", colSize: 8 },
    { field: "sequence", colSize: 8 },
    { field: "requirement_section_id" },
    { field: "base_requirement_id" },
    { field: "question" },
    { field: "required_document_types" },
    { field: "allow_text_area", colSize: 8 },
    { field: "is_mandatory", colSize: 8 },
    { field: "preserve_for_renewal", colSize: 8 },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime43.jsx)(
    EntityDrawer,
    {
      title: "requirement",
      formFields,
      initialValues,
      id,
      open,
      onClose,
      screenQueryName: "getRequirementDetails" /* GetRequirementDetails */,
      entityName: entityNames.REQUIREMENT,
      editRoute: "/ccfg/requirement-detail/:id" /* RequirementDetail */,
      relativePath: "/ccfg/country-template-detail/:id" /* CountryTemplateDetail */
    }
  );
};

// src/pages/CountryTemplate/RequirementInfo.tsx
var import_jsx_runtime44 = require("react/jsx-runtime");
var Button3 = import_stack9_ui3.S9Button.default;
var RequirementInfo = ({ countryTemplateId, initialValues }) => {
  const [isOpen, setIsOpen] = (0, import_react20.useState)(false);
  const refresh = (0, import_stack9_ui3.useRefreshList)();
  const columns = [
    {
      label: "Code",
      field: "code",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.Text,
      value: "{{code}}",
      options: {
        linkProp: "/ccfg/requirement-detail/{{id}}"
      }
    },
    {
      label: "Section name",
      field: "section_name",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.Text,
      value: "{{requirement_section.name}}"
    },
    {
      label: "Question",
      field: "question",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.Text,
      value: "{{question}}"
    },
    {
      label: "Sequence",
      field: "sequence",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.Text,
      value: "{{sequence}}"
    },
    {
      label: "Question type",
      field: "question_type",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.Text,
      value: "{{question_type}}"
    },
    {
      label: "Is mandatory",
      field: "is_mandatory",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.EnumTags,
      value: "{{is_mandatory}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    },
    {
      label: "Preserve for renewal",
      field: "preserve_for_renewal",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.EnumTags,
      value: "{{preserve_for_renewal}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    },
    {
      label: "Is active",
      field: "is_active",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.EnumTags,
      value: "{{is_active}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    },
    {
      label: "Base requirement code",
      field: "base_requirement.code",
      renderAs: import_stack9_sdk25.ColumnConfigurationRenderAs.Text,
      value: "{{base_requirement.code}}",
      options: {
        linkProp: "/ccfg/base-requirement-detail/{{base_requirement_id}}"
      }
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
    ListTable,
    {
      showFilter: true,
      searchFields: [
        "code",
        "question",
        "base_requirement.code",
        "base_requirement.question",
        "requirement_section.name"
      ],
      queryVars: { countryTemplateId },
      columns,
      title: "Requirements",
      queryName: "getrequirementsbycountrytemplate",
      children: /* @__PURE__ */ (0, import_jsx_runtime44.jsxs)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(import_stack9_ui3.Space, { children: /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
              Button3,
              {
                label: "Create",
                onClick: () => setIsOpen(true),
                type: "primary"
              }
            ) }),
            /* @__PURE__ */ (0, import_jsx_runtime44.jsx)(
              RequirementDrawer,
              {
                initialValues,
                open: isOpen,
                onClose: () => {
                  setIsOpen(false);
                  refresh();
                }
              }
            )
          ]
        }
      )
    }
  );
};
var RequirementInfo_default = RequirementInfo;

// src/pages/CountryTemplate/SurveyQuestionInfo.tsx
var import_stack9_sdk26 = require("@april9/stack9-sdk");
var import_jsx_runtime45 = require("react/jsx-runtime");
var Drawer5 = SurveyQuestionDrawerAction_exports.default;
var SurveyQuestionInfo = ({ countryTemplateId, initialValues }) => {
  const columns = [
    {
      label: "Code",
      field: "code",
      renderAs: import_stack9_sdk26.ColumnConfigurationRenderAs.CustomComponent,
      value: "{{code}}",
      options: {
        customComponentName: "SurveyQuestionDrawerAction"
      }
    },
    {
      label: "Question",
      field: "question",
      renderAs: import_stack9_sdk26.ColumnConfigurationRenderAs.Text,
      value: "{{question}}"
    },
    {
      label: "Sequence",
      field: "sequence",
      renderAs: import_stack9_sdk26.ColumnConfigurationRenderAs.Text,
      value: "{{sequence}}"
    },
    {
      label: "Is active",
      field: "is_active",
      renderAs: import_stack9_sdk26.ColumnConfigurationRenderAs.EnumTags,
      value: "{{is_active}}",
      options: {
        enumColor: {
          Yes: "green",
          No: "red"
        }
      }
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
    ListTable,
    {
      queryVars: { countryTemplateId },
      columns,
      title: "Survey Questions",
      queryName: "getsurveyquestionsbycountrytemplate",
      children: /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: /* @__PURE__ */ (0, import_jsx_runtime45.jsx)(Drawer5, { row: __spreadValues({}, initialValues) })
        }
      )
    }
  );
};
var SurveyQuestionInfo_default = SurveyQuestionInfo;

// src/pages/CountryTemplate/CountryTemplateDetail.tsx
var import_jsx_runtime46 = require("react/jsx-runtime");
var S9CustomEntityFormPage10 = ui32.S9CustomEntityFormPage.default;
var S9Tabs6 = ui32.S9Tabs.default;
var CountryTemplateDetail = () => {
  const { schema } = (0, import_stack9_react8.useEntitySchema)(entityNames.COUNTRY_TEMPLATE);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react8.useRouteAndQueryParams)();
  const items = (0, import_react21.useMemo)(
    () => [
      {
        label: "Details",
        key: "details",
        children: /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(CountryTemplateForm_default, { schema })
      },
      {
        label: "Requirements",
        key: "requirements",
        children: /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
          RequirementInfo_default,
          {
            countryTemplateId: +id,
            initialValues: { country_template_id: +id }
          }
        )
      },
      {
        label: "Survey questions",
        key: "survey_questions",
        children: /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
          SurveyQuestionInfo_default,
          {
            countryTemplateId: +id,
            initialValues: { country_template_id: +id }
          }
        )
      }
    ],
    [id, schema]
  );
  return /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(
    S9CustomEntityFormPage10,
    {
      entityKey: entityNames.COUNTRY_TEMPLATE,
      screenQueryName: "getCountryTemplateDetails" /* GetCountryTemplateDetails */,
      entityId: +id,
      title: (data) => `Country template #${data == null ? void 0 : data.id}`,
      cancelLink: "/ccfg/country-template/list" /* CountryTemplateList */,
      children: /* @__PURE__ */ (0, import_jsx_runtime46.jsx)(S9Tabs6, { items })
    }
  );
};
var CountryTemplateDetail_default = CountryTemplateDetail;

// src/pages/Organisation/Index.tsx
var import_stack9_react9 = require("@april9/stack9-react");
var ui36 = __toESM(require("@april9/stack9-ui"));

// src/pages/Organisation/AddressDetail.tsx
var ui33 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime47 = require("react/jsx-runtime");
var S9PageSection18 = ui33.S9PageSection.default;
var Fieldset7 = ui33.S9Fieldset.default;
var AddressDetail = ({ schema }) => {
  const fields = [
    {
      field: "address_line_1",
      colSize: 12
    },
    {
      field: "address_line_2",
      colSize: 12
    },
    {
      field: "suburb",
      colSize: 8
    },
    {
      field: "state",
      colSize: 8
    },
    {
      field: "post_code",
      colSize: 8
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(S9PageSection18, { title: "Address details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime47.jsx)(
    Fieldset7,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var AddressDetail_default = AddressDetail;

// src/pages/Organisation/OrganisationDetail.tsx
var ui34 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime48 = require("react/jsx-runtime");
var Fieldset8 = ui34.S9Fieldset.default;
var S9PageSection20 = ui34.S9PageSection.default;
var OrganisationDetail = ({ schema }) => {
  const fields = [
    {
      field: "registered_name",
      colSize: 18
    },
    {
      field: "country",
      colSize: 6
    },
    {
      field: "trade_as_name",
      colSize: 18
    },
    {
      field: "registered_number",
      colSize: 6
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(S9PageSection20, { title: "Organisation details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime48.jsx)(
    Fieldset8,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var OrganisationDetail_default = OrganisationDetail;

// src/pages/Organisation/ProfileDetail.tsx
var ui35 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime49 = require("react/jsx-runtime");
var Fieldset9 = ui35.S9Fieldset.default;
var S9PageSection22 = ui35.S9PageSection.default;
var ProfileDetail = ({ schema }) => {
  const fields = [
    {
      field: "org_number",
      label: "Organisation number",
      colSize: 12
    },
    {
      field: "account_id",
      label: "Account number",
      colSize: 12
    },
    {
      field: "main_industry_name",
      label: "Main industry name",
      colSize: 12
    },
    {
      field: "main_industry_code",
      label: "Main industry code",
      colSize: 12
    },
    {
      field: "secondary_industry_name",
      label: "Secondary industry name",
      colSize: 12
    },
    {
      field: "secondary_industry_code",
      label: "Secondary industry code",
      colSize: 12
    },
    {
      field: "partner_id",
      label: "Partner",
      colSize: 8
    },
    {
      field: "payment_waiver_end_dt",
      colSize: 8
    },
    {
      field: "is_active",
      colSize: 8
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(S9PageSection22, { title: "Profile details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime49.jsx)(
    Fieldset9,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var ProfileDetail_default = ProfileDetail;

// src/pages/Organisation/Index.tsx
var import_jsx_runtime50 = require("react/jsx-runtime");
var S9CustomEntityFormPage12 = ui36.S9CustomEntityFormPage.default;
var Index4 = () => {
  const { schema } = (0, import_stack9_react9.useEntitySchema)(entityNames.ORGANISATION);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react9.useRouteAndQueryParams)();
  return /* @__PURE__ */ (0, import_jsx_runtime50.jsxs)(
    S9CustomEntityFormPage12,
    {
      entityKey: entityNames.ORGANISATION,
      screenQueryName: "getOrganisationDetails" /* GetOrganisationDetails */,
      entityId: +id,
      title: (data) => `Organisation #${data == null ? void 0 : data.id}`,
      cancelLink: "/crm/organisation/list" /* OrganisationList */,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(OrganisationDetail_default, { schema }),
        /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(AddressDetail_default, { schema }),
        /* @__PURE__ */ (0, import_jsx_runtime50.jsx)(ProfileDetail_default, { schema })
      ]
    }
  );
};
var Index_default2 = Index4;

// src/pages/Partner/PartnerDetail.tsx
var import_stack9_react10 = require("@april9/stack9-react");
var ui39 = __toESM(require("@april9/stack9-ui"));

// src/pages/Partner/PartnerForm.tsx
var ui37 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime51 = require("react/jsx-runtime");
var Fieldset10 = ui37.S9Fieldset.default;
var S9PageSection24 = ui37.S9PageSection.default;
var PartnerForm = ({ schema }) => {
  const fields = [
    {
      field: "registered_name"
    },
    {
      field: "trade_as_name",
      colSize: 12
    },
    {
      field: "registered_number",
      colSize: 12
    },
    {
      field: "address_line_1",
      colSize: 12
    },
    {
      field: "address_line_2",
      colSize: 12
    },
    {
      field: "suburb",
      colSize: 6
    },
    {
      field: "post_code",
      colSize: 6
    },
    {
      field: "state",
      colSize: 6
    },
    {
      field: "country",
      colSize: 6
    },
    {
      field: "is_active",
      colSize: 8
    },
    {
      field: "logo",
      colSize: 8
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(S9PageSection24, { title: "Partner details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime51.jsx)(
    Fieldset10,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var PartnerForm_default = PartnerForm;

// src/pages/Partner/PartnerUser.tsx
var import_react22 = require("react");
var import_stack9_sdk27 = require("@april9/stack9-sdk");
var ui38 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime52 = require("react/jsx-runtime");
var Button4 = ui38.S9Button.default;
var { Space: Space9, useRefreshList: useRefreshList15 } = ui38;
var PartnerUser = ({ id }) => {
  const [isOpen, setIsOpen] = (0, import_react22.useState)(false);
  const refresh = useRefreshList15();
  return /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
    ListTable,
    {
      queryVars: {
        partnerId: id
      },
      queryName: "getusersbypartner",
      title: "Users",
      columns: [
        {
          label: "User name",
          field: "username",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{username}}",
          options: {
            linkProp: "/crm/user/{{id}}"
          }
        },
        {
          label: "First name",
          field: "first_name",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{first_name}}"
        },
        {
          label: "Last name",
          field: "last_name",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{last_name}}"
        },
        {
          label: "Email",
          field: "email",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{email}}"
        },
        {
          label: "Phone",
          field: "phone",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{phone}}"
        },
        {
          label: "Position",
          field: "position",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{position}}"
        },
        {
          label: "Default language",
          field: "default_language",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{default_language}}"
        },
        {
          label: "User groups",
          field: "user_groups",
          value: "{{user_groups.map(item=>item.name)}}",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.EnumTags
        },
        {
          label: "Invited by",
          field: "invited_by_user",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Text,
          value: "{{invited_by_user.full_name}}"
        },
        {
          label: "Created at",
          field: "_created_at",
          renderAs: import_stack9_sdk27.ColumnConfigurationRenderAs.Date,
          value: "{{_created_at}}",
          options: {
            noTime: true
          }
        }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime52.jsxs)(
        "div",
        {
          style: {
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px"
          },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
              EntityDrawer,
              {
                open: isOpen,
                title: "user",
                initialValues: {
                  partner_id: id
                },
                entityName: entityNames.USER,
                formFields: [
                  "first_name",
                  "last_name",
                  "username",
                  "email",
                  "phone",
                  "is_active",
                  "job_title",
                  "user_groups",
                  "account_id",
                  "position",
                  "default_language",
                  "partner_id",
                  "security_role_id"
                ],
                relativePath: "/crm/partner-detail/:id" /* PartnerDetail */,
                screenQueryName: "getUserDetailsCRM" /* GetUserDetailsCRM */,
                editRoute: "/crm/user/:id" /* UserDetail */,
                onClose: () => {
                  setIsOpen(false);
                  refresh();
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(Space9, { children: /* @__PURE__ */ (0, import_jsx_runtime52.jsx)(
              Button4,
              {
                label: "Create",
                onClick: () => setIsOpen(true),
                type: "primary"
              }
            ) })
          ]
        }
      )
    }
  );
};
var PartnerUser_default = PartnerUser;

// src/pages/Partner/PartnerDetail.tsx
var import_jsx_runtime53 = require("react/jsx-runtime");
var S9CustomEntityFormPage14 = ui39.S9CustomEntityFormPage.default;
var Index5 = () => {
  const { schema } = (0, import_stack9_react10.useEntitySchema)(entityNames.PARTNER);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react10.useRouteAndQueryParams)();
  return /* @__PURE__ */ (0, import_jsx_runtime53.jsxs)(
    S9CustomEntityFormPage14,
    {
      entityKey: entityNames.PARTNER,
      screenQueryName: "getPartnerDetails" /* GetPartnerDetails */,
      entityId: +id,
      title: (data) => `Partner #${data == null ? void 0 : data.id}`,
      cancelLink: "/crm/partner/list" /* PartnerList */,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(PartnerForm_default, { schema }),
        /* @__PURE__ */ (0, import_jsx_runtime53.jsx)(PartnerUser_default, { id: +id })
      ]
    }
  );
};
var PartnerDetail_default = Index5;

// src/pages/Requirement/RequirementDetail.tsx
var import_stack9_react11 = require("@april9/stack9-react");
var ui43 = __toESM(require("@april9/stack9-ui"));

// src/pages/Requirement/DependenciesDetail.tsx
var import_react23 = require("react");
var import_stack9_sdk28 = require("@april9/stack9-sdk");
var ui40 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime54 = require("react/jsx-runtime");
var Button5 = ui40.S9Button.default;
var { Space: Space10, useRefreshList: useRefreshList16 } = ui40;
var DependenciesDetail = ({ id }) => {
  const [isOpen, setIsOpen] = (0, import_react23.useState)(false);
  const refresh = useRefreshList16();
  return /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(
    ListTable,
    {
      queryVars: {
        requirementId: id
      },
      queryName: "getdependenciesbyrequirement",
      title: "Dependencies",
      columns: [
        {
          label: "Requirement summary",
          field: "requirement.summary",
          renderAs: import_stack9_sdk28.ColumnConfigurationRenderAs.CustomComponent,
          options: {
            customComponentName: "DependencyAction"
          },
          value: "{{sequence}}"
        },
        {
          label: "Option set",
          field: "option_set.option",
          renderAs: import_stack9_sdk28.ColumnConfigurationRenderAs.Text,
          value: "{{option_set.option}}"
        }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime54.jsxs)(
        "div",
        {
          style: { display: "flex", justifyContent: "end", marginBottom: "15px" },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(
              DependencyDrawer,
              {
                initialValues: { requirement_id: id },
                open: isOpen,
                onClose: () => {
                  setIsOpen(false);
                  refresh();
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(Space10, { children: /* @__PURE__ */ (0, import_jsx_runtime54.jsx)(
              Button5,
              {
                label: "Create",
                onClick: () => setIsOpen(true),
                type: "primary"
              }
            ) })
          ]
        }
      )
    }
  );
};
var DependenciesDetail_default = DependenciesDetail;

// src/pages/Requirement/OptionSetDetail.tsx
var import_react24 = require("react");
var import_stack9_sdk29 = require("@april9/stack9-sdk");
var ui41 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime55 = require("react/jsx-runtime");
var Button6 = ui41.S9Button.default;
var { Space: Space11, useRefreshList: useRefreshList17 } = ui41;
var OptionSetDetail = ({ id }) => {
  const [isOpen, setIsOpen] = (0, import_react24.useState)(false);
  const refresh = useRefreshList17();
  return /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
    ListTable,
    {
      queryVars: {
        requirementId: id
      },
      queryName: "getoptionsetbyrequirement",
      title: "Option set",
      columns: [
        {
          label: "Sequence",
          field: "sequence",
          renderAs: import_stack9_sdk29.ColumnConfigurationRenderAs.Text,
          value: "{{sequence}}"
        },
        {
          label: "Option",
          field: "option",
          renderAs: import_stack9_sdk29.ColumnConfigurationRenderAs.CustomComponent,
          options: {
            customComponentName: "OptionSetAction"
          },
          value: "{{option}}"
        },
        {
          label: "Is correct",
          field: "is_correct",
          renderAs: import_stack9_sdk29.ColumnConfigurationRenderAs.EnumTags,
          value: "{{is_correct}}",
          options: {
            enumColor: {
              Yes: "green",
              No: "red"
            }
          }
        },
        {
          label: "Negative response",
          field: "negative_response",
          renderAs: import_stack9_sdk29.ColumnConfigurationRenderAs.Text,
          value: "{{negative_response}}"
        },
        {
          label: "Is active",
          field: "is_active",
          renderAs: import_stack9_sdk29.ColumnConfigurationRenderAs.EnumTags,
          value: "{{is_active}}",
          options: {
            enumColor: {
              Yes: "green",
              No: "red"
            }
          }
        }
      ],
      children: /* @__PURE__ */ (0, import_jsx_runtime55.jsxs)(
        "div",
        {
          style: { display: "flex", justifyContent: "end", marginBottom: "15px" },
          children: [
            /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
              OptionSetDrawer,
              {
                initialValues: { requirement_id: id },
                open: isOpen,
                onClose: () => {
                  setIsOpen(false);
                  refresh();
                }
              }
            ),
            /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(Space11, { children: /* @__PURE__ */ (0, import_jsx_runtime55.jsx)(
              Button6,
              {
                label: "Create",
                onClick: () => setIsOpen(true),
                type: "primary"
              }
            ) })
          ]
        }
      )
    }
  );
};
var OptionSetDetail_default = OptionSetDetail;

// src/pages/Requirement/RequirementForm.tsx
var ui42 = __toESM(require("@april9/stack9-ui"));
var import_jsx_runtime56 = require("react/jsx-runtime");
var Fieldset11 = ui42.S9Fieldset.default;
var S9PageSection26 = ui42.S9PageSection.default;
var BaseRequirementForm2 = ({ schema }) => {
  const fields = [
    {
      field: "summary"
    },
    {
      field: "code",
      colSize: 6
    },
    {
      field: "question_type",
      colSize: 9
    },
    {
      field: "sequence",
      colSize: 3
    },
    {
      field: "base_requirement_id",
      colSize: 3
    },
    {
      field: "requirement_section_id",
      label: "Requirement section"
    },
    {
      field: "required_document_types"
    },
    {
      field: "allow_text_area",
      colSize: 6
    },
    {
      field: "is_mandatory",
      colSize: 6
    },
    {
      field: "preserve_for_renewal",
      colSize: 6
    },
    {
      field: "is_active",
      colSize: 6
    },
    {
      field: "question"
    },
    {
      field: "lang_set"
    }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(S9PageSection26, { title: "Requirement details", size: "sm", children: schema && /* @__PURE__ */ (0, import_jsx_runtime56.jsx)(
    Fieldset11,
    {
      context: { id: void 0 },
      schema,
      formFields: fields
    }
  ) });
};
var RequirementForm_default = BaseRequirementForm2;

// src/pages/Requirement/RequirementDetail.tsx
var import_jsx_runtime57 = require("react/jsx-runtime");
var S9CustomEntityFormPage16 = ui43.S9CustomEntityFormPage.default;
var Index6 = () => {
  const { schema } = (0, import_stack9_react11.useEntitySchema)(entityNames.REQUIREMENT);
  const {
    routeParams: { id = "" }
  } = (0, import_stack9_react11.useRouteAndQueryParams)();
  return /* @__PURE__ */ (0, import_jsx_runtime57.jsxs)(
    S9CustomEntityFormPage16,
    {
      entityKey: entityNames.REQUIREMENT,
      screenQueryName: "getRequirementDetails" /* GetRequirementDetails */,
      entityId: +id,
      title: (data) => `Requirement #${data == null ? void 0 : data.id}`,
      cancelLink: "/ccfg/country-template/list" /* CountryTemplateList */,
      children: [
        /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(RequirementForm_default, { schema }),
        /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(OptionSetDetail_default, { id: +id }),
        /* @__PURE__ */ (0, import_jsx_runtime57.jsx)(DependenciesDetail_default, { id: +id })
      ]
    }
  );
};
var RequirementDetail_default = Index6;

// src/components/ActionBarComponent.tsx
var import_react25 = require("react");
var import_react_router_dom = require("react-router-dom");
var ui44 = __toESM(require("@april9/stack9-ui"));

// src/components/ApplicationDrawer/ApplicationDrawer.tsx
var import_jsx_runtime58 = require("react/jsx-runtime");
var ApplicationDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "country", colSize: 12 },
    { field: "published_template_uuid", colSize: 12 },
    { field: "organisation_id", colSize: 12 },
    { field: "partner_id", colSize: 12 },
    { field: "submitted_by_id", colSize: 12 },
    { field: "submitted_dt", colSize: 12 },
    { field: "assessed_by_id", colSize: 12 },
    { field: "certification_number", colSize: 12 },
    { field: "award_dt", colSize: 12 },
    { field: "expiry_dt", colSize: 12 },
    { field: "organisation_size", colSize: 12 },
    { field: "payable_amount", colSize: 12 }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime58.jsx)(
    EntityDrawer,
    {
      title: "application",
      formFields,
      initialValues,
      id,
      open,
      onClose,
      screenQueryName: "getApplicationDetails" /* GetApplicationDetails */,
      entityName: entityNames.APPLICATION,
      editRoute: "/app/application-detail/:id" /* ApplicationDetail */,
      relativePath: "/app/application/list" /* ApplicationList */
    }
  );
};

// src/components/BaseRequirementDrawer/BaseRequirementDrawer.tsx
var import_jsx_runtime59 = require("react/jsx-runtime");
var BaseRequirementDrawer = ({
  open,
  onClose,
  id,
  initialValues = {}
}) => {
  const formFields = [
    { field: "question_type", colSize: 12 },
    { field: "sequence", colSize: 12 },
    { field: "requirement_section_id" },
    { field: "question" },
    { field: "required_document_types" },
    { field: "allow_text_area", colSize: 8 },
    { field: "is_mandatory", colSize: 8 },
    { field: "preserve_for_renewal", colSize: 8 },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime59.jsx)(
    EntityDrawer,
    {
      title: "base requirement",
      open,
      id,
      formFields,
      initialValues,
      onClose,
      screenQueryName: "getBaseRequirementDetails" /* GetBaseRequirementDetails */,
      entityName: entityNames.BASE_REQUIREMENT,
      editRoute: "/ccfg/base-requirement-detail/:id" /* BaseRequirementDetail */,
      relativePath: "/ccfg/base-requirement/list" /* BaseRequirementList */
    }
  );
};

// src/components/CountrySetupDrawer/CountrySetupDrawer.tsx
var import_jsx_runtime60 = require("react/jsx-runtime");
var CountrySetupDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [{ field: "country" }];
  return /* @__PURE__ */ (0, import_jsx_runtime60.jsx)(
    EntityDrawer,
    {
      title: "country setup",
      open,
      id,
      formFields,
      initialValues,
      onClose,
      screenQueryName: "getCountrySetupDetails" /* GetCountrySetupDetails */,
      entityName: entityNames.COUNTRY_SETUP,
      editRoute: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */,
      relativePath: "/ccfg/country-setup-detail/:id" /* CountrySetupDetail */
    }
  );
};

// src/components/CountryTemplateDrawer/CountryTemplateDrawer.tsx
var import_jsx_runtime61 = require("react/jsx-runtime");
var CountryTemplateDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "country" },
    { field: "include_all_base_requirements" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime61.jsx)(
    EntityDrawer,
    {
      title: "country template",
      open,
      id,
      formFields,
      initialValues,
      onClose,
      screenQueryName: "getCountryTemplateDetails" /* GetCountryTemplateDetails */,
      entityName: entityNames.COUNTRY_TEMPLATE,
      editRoute: "/ccfg/country-template-detail/:id" /* CountryTemplateDetail */,
      relativePath: "/ccfg/country-template-detail/:id" /* CountryTemplateDetail */
    }
  );
};

// src/components/OrganisationDrawer/OrganisationDrawer.tsx
var import_jsx_runtime62 = require("react/jsx-runtime");
var OrganisationDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "account_id", label: "Account" },
    { field: "registered_name", colSize: 12 },
    { field: "registered_number", colSize: 12 },
    { field: "trade_as_name", colSize: 12 },
    { field: "partner_id", colSize: 12, label: "Partner" },
    { field: "main_industry_name", colSize: 12 },
    { field: "main_industry_code", colSize: 12 },
    {
      field: "secondary_industry_name",
      colSize: 12
    },
    {
      field: "secondary_industry_code",
      colSize: 12
    },
    { field: "payment_waiver_end_dt" },
    { field: "address_line_1" },
    { field: "address_line_2" },
    { field: "suburb", colSize: 6 },
    { field: "post_code", colSize: 6 },
    { field: "state", colSize: 6 },
    { field: "country", colSize: 6 },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime62.jsx)(
    EntityDrawer,
    {
      title: "organisation",
      open,
      onClose,
      screenQueryName: "getOrganisationDetails" /* GetOrganisationDetails */,
      entityName: entityNames.ORGANISATION,
      editRoute: "/crm/organisation-detail/:id" /* OrganisationDetail */,
      relativePath: "/crm/organisation-detail/:id" /* OrganisationDetail */,
      formFields,
      initialValues,
      id
    }
  );
};

// src/components/PartnerDrawer/PartnerDrawer.tsx
var import_jsx_runtime63 = require("react/jsx-runtime");
var PartnerDrawer = ({
  open,
  onClose,
  id,
  initialValues
}) => {
  const formFields = [
    { field: "registered_number" },
    { field: "registered_name" },
    { field: "trade_as_name" },
    { field: "address_line_1" },
    { field: "address_line_2" },
    { field: "suburb", colSize: 6 },
    { field: "post_code", colSize: 6 },
    { field: "state", colSize: 6 },
    { field: "country", colSize: 6 },
    { field: "is_active" }
  ];
  return /* @__PURE__ */ (0, import_jsx_runtime63.jsx)(
    EntityDrawer,
    {
      title: "partner",
      open,
      id,
      formFields,
      initialValues,
      onClose,
      screenQueryName: "getPartnerDetails" /* GetPartnerDetails */,
      entityName: entityNames.PARTNER,
      editRoute: "/crm/partner-detail/:id" /* PartnerDetail */,
      relativePath: "/crm/partner/list" /* PartnerList */
    }
  );
};

// src/components/ActionBarComponent.tsx
var import_jsx_runtime64 = require("react/jsx-runtime");
var Button7 = ui44.S9Button.default;
var { Space: Space13 } = ui44;
var OrganisationActions = () => {
  const [isOpen, setIsOpen] = (0, import_react25.useState)(false);
  const refresh = ui44.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
      OrganisationDrawer,
      {
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Space13, { children: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Button7, { label: "Create", onClick: () => setIsOpen(true), type: "primary" }) })
  ] });
};
var PartnerActions = () => {
  const [isOpen, setIsOpen] = (0, import_react25.useState)(false);
  const refresh = ui44.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
      PartnerDrawer,
      {
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(ui44.Space, { children: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Button7, { label: "Create", onClick: () => setIsOpen(true), type: "primary" }) })
  ] });
};
var ApplicationActions = () => {
  const [isOpen, setIsOpen] = (0, import_react25.useState)(false);
  const refresh = ui44.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
      ApplicationDrawer,
      {
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(ui44.Space, { children: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Button7, { label: "Create", onClick: () => setIsOpen(true), type: "primary" }) })
  ] });
};
var BaseRequirementActions = () => {
  const [isOpen, setIsOpen] = (0, import_react25.useState)(false);
  const refresh = ui44.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
      BaseRequirementDrawer,
      {
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(ui44.Space, { children: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Button7, { label: "Create", onClick: () => setIsOpen(true), type: "primary" }) })
  ] });
};
var CountrySetupActions = () => {
  const [isOpen, setIsOpen] = (0, import_react25.useState)(false);
  const refresh = ui44.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
      CountrySetupDrawer,
      {
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(ui44.Space, { children: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Button7, { label: "Create", onClick: () => setIsOpen(true), type: "primary" }) })
  ] });
};
var CountryTemplateActions = () => {
  const [isOpen, setIsOpen] = (0, import_react25.useState)(false);
  const refresh = ui44.useRefreshList();
  return /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
      CountryTemplateDrawer,
      {
        initialValues: { include_all_base_requirements: true },
        open: isOpen,
        onClose: () => {
          setIsOpen(false);
          refresh();
        }
      }
    ),
    /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(ui44.Space, { children: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(Button7, { label: "Create", onClick: () => setIsOpen(true), type: "primary" }) })
  ] });
};
var ActionBarComponent = (0, import_react25.memo)(
  ({ defaultActions }) => /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_jsx_runtime64.Fragment, { children: [
    defaultActions,
    /* @__PURE__ */ (0, import_jsx_runtime64.jsxs)(import_react_router_dom.Routes, { children: [
      /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(import_react_router_dom.Route, { path: "organisation/list", element: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(OrganisationActions, {}) }),
      /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(import_react_router_dom.Route, { path: "partner/list", element: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(PartnerActions, {}) }),
      /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(import_react_router_dom.Route, { path: "application/list", element: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(ApplicationActions, {}) }),
      /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
        import_react_router_dom.Route,
        {
          path: "base-requirement/list",
          element: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(BaseRequirementActions, {})
        }
      ),
      /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(import_react_router_dom.Route, { path: "country-setup/list", element: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(CountrySetupActions, {}) }),
      /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(
        import_react_router_dom.Route,
        {
          path: "country-template/list",
          element: /* @__PURE__ */ (0, import_jsx_runtime64.jsx)(CountryTemplateActions, {})
        }
      )
    ] })
  ] })
);

// src/index.tsx
var import_jsx_runtime65 = require("react/jsx-runtime");
var components = [
  ApplicationResponseAction_exports,
  FileView_exports,
  BaseOptionSetAction_exports,
  OptionSetAction_exports,
  OccupationDrawerAction_exports,
  IndustryDrawerAction_exports,
  PriceRuleDrawerAction_exports,
  SecondaryIndustryDrawerAction_exports,
  SurveyQuestionDrawerAction_exports,
  DependencyAction_exports,
  PromoCodeDrawerAction_exports
];
var customRoutes = [
  {
    route: "/organisation-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(Index_default2, {})
  },
  {
    route: "/partner-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(PartnerDetail_default, {})
  },
  {
    route: "/application-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(ApplicationDetail_default, {})
  },
  {
    route: "/account-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(Index_default, {})
  },
  {
    route: "/base-requirement-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(BaseRequirementDetail_default, {})
  },
  {
    route: "/requirement-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(RequirementDetail_default, {})
  },
  {
    route: "/country-setup-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(CountrySetupDetail_default, {})
  },
  {
    route: "/country-template-detail/:id",
    component: /* @__PURE__ */ (0, import_jsx_runtime65.jsx)(CountryTemplateDetail_default, {})
  }
];
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ActionBarComponent,
  components,
  customRoutes
});
